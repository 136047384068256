import styled from 'styled-components';

const LoadingComp = styled.div`
    display: flex;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 50px; */
    height: 100%;

    > div.lds-dual-ring {
        display: inline-block;
        width: 40px;
        height: 40px;
    }
    > div.lds-dual-ring:after {
        content: " ";
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        /* border: 4px solid lightgray; */
        border: 4px solid black;
        /* border-color: lightgray transparent lightgray transparent; */
        border-color: black transparent black transparent;
        /* box-shadow:  0px 0px 10px 10px black; */
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
`;

export default function Loading () {
    return (
        <LoadingComp>
            <div class="lds-dual-ring"></div>
        </LoadingComp>
    )
}