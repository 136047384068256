import styled from 'styled-components';

const AlertComp = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 80px;
    background-color: black;
    color: white;
    position: fixed;
    top: 10vh;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 100;
    transition: all 0.5s ease-in-out;
`

export default function Alert ({alert}) {

    return (
        <AlertComp>
            {alert}
        </AlertComp>
    )
}