import styled from 'styled-components'
import axios from 'axios'
import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

const ContactPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        margin-top: 20px;

        ::-webkit-scrollbar {
            display: none;
        }

        > div.input-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            height: auto;

            
            > input {
                width: 100%;
                min-width: 300px;
                height: 30px;
                margin-bottom: 10px;
                border: none;
                border-bottom: 1px solid lightgray;

                :focus {
                    outline: none;
                }
            }
        
            > textarea {
                width: 100%;
                min-width: 300px;
                min-height: 30px;
                max-height: 600px;
                overflow: scroll;
                resize: none;
                margin-bottom: 10px;
                border: none;
                border-bottom: 1px solid lightgray;

                :focus {
                    outline: none;
                }

                ::-webkit-scrollbar {
                    display: none;
                }
            }

            > div#number-box {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                min-width: 300px;
                height: 40px;
                margin-bottom: 10px;

                > input:nth-child(2) {
                    margin-left: 10px;
                }
        
                > input {
                    width: 100%;
                    height: 30px;
                    border: none;
                    border-bottom: 1px solid lightgray;

                    :focus {
                        outline: none;
                    }
                }

            }

            > div#button {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                min-width: 300px;
                margin-bottom: 30px;
                
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 80px;
                    height: 30px;
                    color: rgb(25,144,198);
                    border: 1px solid rgb(25,144,198);
                    border-radius: 0px 5px 0px 5px;
                    
                    :hover {
                        cursor: pointer;
                        opacity: 0.5;
                    }

                    :active {
                        color: red;
                    }
                }
            }
        }
    }
`

export default function Contact () {
    const navigate = useNavigate();
    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ message, setMessage ] = useState('')
    const [ orderNumber, setOrderNumber ] = useState('')

    const textareaRef = useRef();

    const messageSendHandler = async() => {
        console.log('message sent')

        await axios.post(
            `${process.env.REACT_APP_URL}/terms/contact`,
            {
                name: name,
                email: email,
                phone: phone,
                orderNumber: orderNumber,
                message: message,
            }
        )
        .then((res) => {
            console.log(res);
            alert('message sent');
            navigate('/');
        })
        .catch((err) => {
            console.log(err);
            alert('Request failed. Please try again later');
        })
    }

    const textareaHeightHandler = () => {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 5 + "px";
    }

    return (
        <ContactPage>
            <div>
                <div className="input-wrapper">
                    <input placeholder="name (required)" onChange={(el) => { setName(el.target.value) }}/>
                </div>
                <div className="input-wrapper"> 
                    <input placeholder="email (required)" onChange={(el) => { setEmail(el.target.value) }}/>
                </div>
                <div className="input-wrapper">
                    <div id="number-box">
                        <input placeholder="phone" onChange={(el) => { setPhone(el.target.value) }}/>
                        <input placeholder="order number" onChange={(el) => { setOrderNumber(el.target.value) }}/>
                    </div>
                </div>
                <div className="input-wrapper">
                    <textarea 
                    ref={textareaRef}
                    rows={1}
                    placeholder="message"
                    onChange={(el) => { 
                        setMessage(el.target.value)
                        textareaHeightHandler()
                    }}/>
                </div>
                <div className="input-wrapper"> 
                    <div id="button">
                        <div onClick={messageSendHandler}>send</div>
                    </div>
                </div>
            </div>
        </ContactPage>
    )
}