import styled from 'styled-components';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const TermsPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: scroll;
    color: rgb(66,66,66);
`;

const Condition = styled.div`
    white-space: pre-wrap;
    width: 80%;
    height: auto;
    font: 1rem 'Nanum Gothic', sans-serif;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    
    > div#tou {
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-top: 20px; */
    }

    > div.block {
        height: 30px;
    }
`;

export default function Terms () {
    const { idx } = useParams();
    const [ content, setContent ] = useState("");

    const requestTerms = async (idx) => {
        await axios.get(`${process.env.REACT_APP_URL}/terms/use`)
        .then((res) => {
            console.log(res);
            // setContent(res.data.condition);
            setContent(res.data);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        console.log(idx);
        requestTerms(idx);
    }, [])

    useEffect(() => {
        console.log(document.getElementById("privacy"))
        const privacy = document.getElementById("privacy");
        const returns = document.getElementById("returns");
        const signin = document.getElementById("signin");
        const tou = document.getElementById("tou");
        const shipping = document.getElementById("shipping");
        if(privacy && idx === "privacy") {
            privacy.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
        else if(returns && idx === "returns") {
            returns.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
        else if(signin && idx === "signin") {
            signin.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
        else if(tou && idx === "use") {
            tou.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
        else if(shipping && idx === "shipping") {
            shipping.scrollIntoView({ behavior: 'smooth', block: "start" });
        }

    }, [content])

    return (
        <TermsPage>
            <Condition dangerouslySetInnerHTML={ {__html: content} }>
            </Condition>
        </TermsPage>
    )
}