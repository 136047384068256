import styled from 'styled-components';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { 
    setCartItems,
    setPhotos, 
    // setWebImg 
} from '../redux/rootSlice';
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImgSlider from '../components/imgSlider';
import Alert from '../components/alert';
import Loading from '../components/loading';
import { register } from 'swiper/element/bundle';

import AddCartImg from '../image/addcartbt.png';
import BuyNowImg from '../image/buynowbt.png';
import xxsImg from '../image/xxs.png';
import xsImg from '../image/xs.png';
import sImg from '../image/s.png';
import mImg from '../image/m.png';
import lImg from '../image/l.png';
import xlImg from '../image/xl.png';
import xxlImg from '../image/xxl.png';


const ItemPageComp = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    animation: fadein ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadein {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        flex-direction: column;
        /* overflow: scroll;
        ::-webkit-scrollbar {
            display: none;
        } */
    }

    
`;

const ContentCover = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 600px;
    height: 100%;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        height: auto;
        min-width: 100%;
        margin-top: 40px;
    }
`;

const ImgSliderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 600px;

    @media screen and (max-width: 768px) {
        width: 100%;
        /* height: 100%; */
        /* height: ${props => props.windowWidth}px; */
        /* height: 385px; */
    }
`;

const InfomationSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    /* width: 40%; */
    width: 400px;
    height: 100%;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        /* height: 50%; */
        height: auto;
        padding-left: 0px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    > div#itemname {
        margin-top: 130px;
        margin-bottom: 10px;
        height: 40px;

        @media screen and (max-width: 768px) {
            margin: 0px;
            height: 50px;
        }
        
        > img {
            width: auto;
            height: 100%;
        }
    }

    > div#price {
        font-size: 20px;
        /* margin-bottom: 10px; */
        height: 40px;
        margin-top: 10px;

        > img {
            width: auto;
            height: 100%;
        }
    }

    > div#buyadd {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: default;
        width: auto;
        height: 35px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            height: 40px;
            justify-content: space-around;
        }
    
        > div#buynow {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            /* border: 1px solid gray; */
    
            &:hover {
                /* color: rgb(25,144,198); */
                /* border-color: rgb(25,144,198); */
                /* border-color: black; */
                /* background-color: rgb(25,144,198); */
                filter: invert(50%);
            }
    
            &:active {
                color: rgb(18,97,134);
            }

            > img {
                width: auto;
                height: 100%;
            }
        }
        
        > div#addcart {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-left: 15px;

            &:hover {
                /* color: rgb(25,144,198); */
                /* border-color: rgb(25,144,198); */
                /* background-color: red; */
                filter: invert(50%);
            }
    
            &:active {
                color: rgb(18,97,134);
            }

            > img {
                width: auto;
                height: 90%;
            }
        }
    } 

    > div#description {
        width: 202px;
        max-width: 202px;
        height: auto;
        text-align: left;
        padding: 0px;
        border: none;
        resize: none;

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0px;
        }

        > img {
            width: 100%;
            height: auto;
        }
    }

    > div#terms {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 202px;
        height: 30px;
        margin-top: 20px;

        > a {
            text-decoration: none;
            color: gray;
            font-size: 15px;
            font-weight: 500;
        }
    }

    > div#pn {  
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 202px;
        height: 30px;
        margin-top: 40px;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46%;
            height: 30px;
            border: 1px solid gray;
            cursor: pointer;
            font-size: 15px;
            font-weight: 500;
            color: gray;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
`;

const Sizebox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    width: 240px;
    height: auto;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    color: gray;

    > div#app {
        color: red;
    }

    @media screen and (max-width: 768px) {
        justify-content: space-around;
        width: 60%;
        min-width: 200px;
        /* margin: 0px; */
    }
    
    > label {
        width: auto;
        height: 30px;
        
        &:hover {
            color: rgb(25,144,198);
            border-color: rgb(25,144,198);
        }
    
        &:focus {
            outline: none;
        }
        
        @media screen and (max-width: 768px) {
            height: 40px;
        }
        
        > input {
            display: none;
        }

        > input:checked + img {
            filter: invert(50%);
        }

        > img {
            width: auto;
            height: 30px;
    
            &:hover {
                filter: invert(50%);
            }
        }
    }
`;

const LoadingComp = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: rgba(2,2,2,0.5); */
    background-color: rgb(239,239,239);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function ItemPage () {
    register();
    const [ sizeImg, setSizeImg ] = useState({
        "xxs": xxsImg,
        "xs": xsImg,
        "s": sImg,
        "m": mImg,
        "l": lImg,
        "xl": xlImg,
        "xxl": xxlImg
    })
    const navigate = useNavigate();
    const {id} = useParams();
    const [ item, setItem ] = useState([]);
    const [ photos, setPhotos ] = useState([]);
    const [ sizes, setSizes ] = useState([]);
    const [ selectedSize, setSelectedSize ] = useState(null);
    const [ alert, setAlert ] = useState(false);
    // const [ scheduleInfo, setScheduleInfo ] = useState(null);
    const [ prev, setPrev ] = useState(null);
    const [ next, setNext ] = useState(null);   
    const [ isLoading, setIsLoading ] = useState(true);
    const [ itemState, setItemState ] = useState(null);
    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);

    const getItem = async () => {
        console.log("getItem")
        await axios.post(
            `${process.env.REACT_APP_URL}/items/itemReq`,
            {
                id: id
            },
            {
                contentType: "application/json",
                withCredentials: true
            }
        )
        .then((res) => {
            console.log(res)
            setPrev(res.data.prevId);
            setNext(res.data.nextId);
            const itemData = res.data.item;
            setItem(itemData);
            setPhotos(itemData.photos.data);
            setItemState(itemData.state);
            // if(itemData.size.data.attributes.APP) {
            //     setScheduleInfo("Additional production is scheduled")
            // }else {
            //     setScheduleInfo("Sold out")
            // }
            let sizeValue = [];
            for(let el of Object.keys(itemData.size.data.attributes)) {
                if( el !== "sizeId" && el !== "createdAt" && el !== "updatedAt" && el !== "publishedAt" && el !== "APP" ) {
                    if(itemData.size.data.attributes[el] > 0) {
                        sizeValue.push(el);
                    }
                }
            }
            setSizes(sizeValue);
            // setIsLoading(false);
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const addCartHandler = async(mes) => {
        if(!selectedSize) { 
            setAlert("please select size");
            setTimeout(() => {  
                setAlert(false);
            }, 2000)
            return;
        }

        const itemReqValue = {
            id: id,
            name: item.itemName,
            price: item.itemCost,
            cartthumbnail: item.cartThumbnail,
            selectedSize: selectedSize,
            count: 1
        }

        await axios.post(
            `${process.env.REACT_APP_URL}/items/cartReq`,
            {
                rt: "add-cart",
                item: itemReqValue
            },
            {
                contentType: "application/json",
                withCredentials: true
            }
        )
        .then((res) => {
            console.log(res)
            if(mes === "buynow") {
                return navigate("/checkout")
            }
            setAlert("add cart");
            setTimeout(() => {  
                setAlert(false);
            }, 2000)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const pnHandler = (mes) => {
        if(mes === "prev") {
            navigate(`/itempage/${prev}`, {replace: true})
        }
        if(mes === "next") {
            navigate(`/itempage/${next}`, {replace: true})
        }
    }
    
    useEffect(() => {
        console.log(isLoading)
        getItem();
        setTimeout(() => {
            setIsLoading(false);
        }, 500)
    }, [id])

    return (
        <ItemPageComp id="item-page-comp">
            { isLoading && <LoadingComp><Loading /></LoadingComp>}
            {/* { alert? <Alert />: null } */}
            { alert && <Alert alert={alert}/>}
            {photos && 
            <ContentCover id="content-cover">
                {console.log("itempage render")}
                <ImgSliderBox windowWidth={windowWidth}>
                    <ImgSlider
                        photos={photos}
                        id={id}
                    />
                </ImgSliderBox>

                <InfomationSide>
                    <div id="itemname">
                        <img src={item.itemNameImg} />
                    </div>
                    <div id="description"> 
                        <img src={item.itemDescImg} />
                    </div>
                    <div id="price">
                        <img src={item.itemCostImg} />
                    </div>
                    <Sizebox>
                        {
                            itemState === "sale"?
                            sizes.map((el, index) => {
                                return (
                                    <label key={index}>
                                        <input type="radio" name="size" value={selectedSize} onChange={() => {setSelectedSize(el)}} />
                                        <img src={sizeImg[el]}/>
                                    </label>
                                )
                            }):
                            <div id="app">{itemState}</div>
                        }

                    </Sizebox>
                    {
                        itemState === "sale" &&
                        <div id="buyadd"> 
                            <div id="buynow" onClick={() => { addCartHandler("buynow") }}>
                                <img src={BuyNowImg} />
                            </div>
                            <div id="addcart" onClick={addCartHandler}>
                                <img src={AddCartImg} />
                            </div>
                        </div>
                    }
                    <div id="pn">
                        <div onClick={()=>{
                            // swiperElRef.current.swiper.destroy(); 
                            // navigate(`/itempage/${prev}`, {replace: true})}
                            pnHandler("prev")}
                        }>prev</div>
                        <div onClick={()=>{
                            // swiperElRef.current.swiper.destroy();
                            // navigate(`/itempage/${next}`, {replace: true})}
                            pnHandler("next")}
                        }>next</div>
                    </div>
                    <div id="terms">
                        <a href="/terms/returns">returns</a>
                        <a href="/terms/shipping">shipping</a>
                        <a href="/terms">terms</a>
                    </div>
                </InfomationSide>
            </ContentCover>
            }
        </ItemPageComp>
    )
}