import { createSlice } from "@reduxjs/toolkit";

export const rootSlice = createSlice({
    name: "user",

    initialState: {
        isUserLogin: false,
        cartItems: [],
        outOfStockList: [],
        // checkoutItems: [],
        lastName: "",
        firstName: "",
        email: "",
        phone: "",
        country: "select",
        postalCode: "",
        province: "",
        provinceCode: "",
        address: "",
        city: "",
        apartment: "",
        userKey: "",

        shippingCharge: 0,
        userAddressList: null,
        selectedShippingMethod: {},
        // // isCartOpen: false,
        photos: [],
        // // checkout
        // address: null,
        // checkoutCompState: "information",
        // checkoutData: [],
        webImg: {},
    },

    reducers: {
        // setCheckoutItems: (state, action) => {
        //     console.log(action)
        //     state.checkoutItems = action.payload;
        // },
        setIsUserLogin: (state, action) => {
            state.isUserLogin = action.payload;
        },
        setCartItems: (state, action) => {
            state.cartItems = action.payload;
        },
        setOutOfStockList: (state, action) => {
            state.outOfStockList = action.payload;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPhone: (state, action) => {
            console.log(action)
            state.phone = action.payload;
        },
        setCountry: (state, action) => {
            console.log(action)
            state.country = action.payload;
        },
        setPostalCode: (state, action) => {
            console.log("setPostalcode >>>>>", action)
            state.postalCode = action.payload;
        },
        setProvince: (state, action) => {
            state.province = action.payload;
        },
        setProvinceCode: (state, action) => {
            state.provinceCode = action.payload;
        },
        setAddress: (state, action) => {
            console.log(action)
            state.address = action.payload;
        },
        setCity: (state, action) => {
            state.city = action.payload;
        },
        setApartment: (state, action) => {
            console.log(action)
            state.apartment = action.payload;
        },
        setShippingCharge: (state, action) => {
            state.shippingCharge = action.payload;
        },
        setUserAddressList: (state, action) => {
            state.userAddressList = action.payload;
        },
        setSelectedShippingMethod: (state, action) => {
            state.selectedShippingMethod = action.payload;
        },
        setPhotos: (state, action) => {
            state.photos = action.payload;
        },
        setUserKey: (state, action) => {
            state.userKey = action.payload;
        },
        setWebImg: (state, action) => {
            state.webImg = action.payload;
        },
    },
});

export const {
    // setCheckoutItems,
    setIsUserLogin,
    setCartItems,
    setOutOfStockList,
    setLastName,
    setFirstName,
    setEmail,
    setPhone,
    setCountry,
    setPostalCode,
    setProvince,
    setProvinceCode,
    setAddress,
    setCity,
    setApartment,
    setShippingCharge,
    setUserAddressList,
    setSelectedShippingMethod,
    setPhotos,
    setUserKey,
    setWebImg,
} = rootSlice.actions;

export default rootSlice.reducer;