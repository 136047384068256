import styled from "styled-components";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Loading from './loading';

const PayPalButtonComp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
`;

export default function PayPalButton() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const paypalButtonCompRef = useRef();

    const lastName = useSelector((state) => state.user.lastName);
    const firstName = useSelector((state) => state.user.firstName);
    const email = useSelector((state) => state.user.email);
    const phone = useSelector((state) => state.user.phone);
    const country = useSelector((state) => state.user.country);
    const postalCode = useSelector((state) => state.user.postalCode);
    const province = useSelector((state) => state.user.province);
    const address = useSelector((state) => state.user.address);
    const city = useSelector((state) => state.user.city);
    const apartment = useSelector((state) => state.user.apartment);

    useEffect(() => {
        if(paypalButtonCompRef.current.firstChild) {
            return;
        }
        window.paypal.Buttons({
            style: {
                shape: "rect",
                color: "white",
                layout: "vertical",
                label: "paypal",
            },
            createOrder: async (data, actions, err) => {
                return axios.post(
                    `${process.env.REACT_APP_URL}/paypal/paypalCreateOrder`,
                    {
                        data: {
                            lastName: lastName,
                            firstName: firstName,
                            email: email,
                            phone: phone,
                            country: country,
                            postalCode: postalCode,
                            province: province,
                            address: address,
                            city: city,
                            apartment: apartment,
                        }
                    },
                    {
                        withCredentials: true,
                        sameSite: "none",
                    }
                )
                .then((response) => { 
                    console.log(response)
                    return response.data; 
                })
                .then((order) => { 
                    return order.id 
                })
                .catch((err) => {
                    console.log(err)
                    if(err.response.data === "token expired") {
                        navigate("/");
                    }
                    return;
                })
            },
            onApprove: async (data, actions) => {
                console.log(data)
                return axios.post(
                    `${process.env.REACT_APP_URL}/paypal/paypalCapturePayment/${data.orderID}`,
                    {
                        data: null
                    },{
                        withCredentials: true,
                        sameSite: "none",
                    }
                )
                .then((response) => { 
                    console.log(response);
                    response.json(); 
                    navigate("/success?payment=paypal")
                })
                .then((orderData) => {
                    console.log(orderData);
                })
            },
            onError: (err) => {
                console.log(err);
            },
            onCancel: (data) => {
                return;
            }
        })
        .render(paypalButtonCompRef.current);
    }, []);

    return (
        <PayPalButtonComp ref={paypalButtonCompRef}> 
        </PayPalButtonComp>
    );
}