import styled from 'styled-components';
import axios from 'axios';
import CartItem from '../components/cartitem';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setCheckoutInfos } from '../redux/rootSlice';
import { setCartItems, setOutOfStockList } from '../redux/rootSlice';
import { useNavigate } from 'react-router-dom';

const CartPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */

    div#container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-width: 300px;
        height: 85%;
        /* border: 1px solid blue; */
        /* color: gray; */

        > div#itemList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 100px;
        }

        > div#termsCheckBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: 'Nanum Gothic', sans-serif;
            margin-top: 10px;
            margin-bottom: 10px;
            opacity: 0.7;

            > input {
                margin-left: 10px;
            }
            
            > a {
                :hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }

            :hover {
                cursor: default;
                opacity: 1;
            }
        }
    }
`;

const Checkout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
    height: 80px;
    /* border: 1px solid black; */
    /* border-radius: 5px; */
    /* color: rgb(68,68,68); */
    color: black;
    /* color: rgb(66,66,66); */
    
    :hover {
        cursor: pointer;
        /* opacity: 0.5; */
        /* color: rgb(66,66,66); */
        /* border: 1px solid rgb(66,66,66); */
        filter: invert(50%);
    }

    :active {
        /* color: rgb(18,97,134); */
        opacity: 0.5;
    }
`;


export default function Cart ({ 
    cartItems, 
    setCartItems,
    contentHandler,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const outOfStockList = useSelector(state => state.user.outOfStockList);
    const [ cartTotal, setCartTotal ] = useState(0);

    const checkboxRef = useRef();

    const checkoutHandler = async () => {
        if(!checkboxRef.current.checked) {
            alert("이용약관에 동의해주세요.");
            return;
        }
        navigate("/checkout")
    }

    useEffect(() => {
        // contentHandler("cart-refresh");
        let total = 0;
        cartItems.forEach((item) => {
            total += item.price * item.count;
        })
        setCartTotal(total);
    }, [cartItems])

    return (
        <CartPage>
            <div id="container">
                {
                    cartItems.length === 0 ?
                    null:
                    cartItems.map((item, index) => {
                        return (
                            <CartItem 
                            key={index} 
                            item={item} 
                            idx={index} 
                            length={cartItems.length}
                            contentHandler={contentHandler}
                            cartItems={cartItems}
                            setCartItems={setCartItems}
                            />
                        )
                    })
                }
                {
                    cartItems.length === 0?
                    null :
                    <div id="termsCheckBox">
                        <a href="/terms/use">이용약관</a>에 동의합니다.
                        <input type="checkbox" ref={checkboxRef}/>
                    </div>
                }
                {
                    cartItems.length === 0 ?
                    null :
                    <Checkout onClick={checkoutHandler} >
                        ₩{cartTotal} checkout
                    </Checkout>
                }
            </div>
        </CartPage>
    )
}