import styled from 'styled-components';
import Cart from '../components/cart';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    setIsUserLogin,
    setCartItems,
    setWebImg,
} from '../redux/rootSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import cartImg from '../image/cartbt.png';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 300px;
    height: 100vh;
    animation: fadein ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadein {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 100px;
        height: 105vh;
        position: relative;
        overflow: scroll;
    }
`;

const MainCompContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    min-width: 400px;
    height: 100%;
    /* border: 1px solid blue; */

    > div#item_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        /* border: 1px solid black; */

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 40px;
            cursor: default;

            :hover {
                /* filter: invert(100%); */
                color: black;
                transition: 0.5s;
            }

            > img {
                height: 100%;
                width: auto;

                :hover {
                    filter: invert(50%);
                    transition: 0.5s;
                }
            }
        }
    }
    div.item-wrapper {
        width: 100%;
        height: auto;

        > img {
            width: 100%;
            height: auto;
        }
    }

    @media screen and (max-width: 768px) {
        width: 90%;
        max-width: 400px;
        min-width: 300px;
        height: 96vh;
    }

    > div#cart-list {
        width: 100%;
        height: auto;
        
        > div#item-box {
            background-color: rgb(207, 209, 213);
            width: 100%;
            height: 100px;
        }

        > img {
            width: 100%;
            height: auto;
        }
    }

    > div#cart-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        margin-top: 0px;
        cursor: default;
        margin-top: 20px;

        > img {
            height: 100%;
            /* border: 1px solid black; */
    
            &:hover {
                color: black;
                filter: invert(50%);
            }
        }
    }
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 300px;
    }
`;

const ContentBoxWrapper = styled.div`
    width: 100%;
    transition: all 0.4s ease-out;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 300px;
    }

    &#login {
        height: ${
            props => 
            props.content_display === "login"||
            props.content_display === "signup-open"||
            props.content_display === "signup-close"?
            `${props.content_height}px`: 
            "0px"
        };
    }
    &#item {
        height: ${props => props.content_display === "item"? `${props.content_height}px`: "0px"};
    }
    &#cart {
        height: ${
            props => 
            props.content_display === "cart"||
            props.content_display === "cart-refresh"? 
            `${props.content_height}px`:
            "0px"
        };
    }
`;

const Footer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column-reverse;
        height: 9vh;
    }

    > div#info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 20px;

        @media screen and (max-width: 768px) {
            margin-left: 0px;
            height: 5vh;
        }

        > div#comp-name {
            font-size: 15px;
        }

        > div#enter-info {
            font-size: 12px;
            opacity: 0.8;
        }
    }

    > div#terms {
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            height: 4vh;
        }

        > a {
            text-decoration: none;
            font-size: 15px;
            font-weight: 100;
            margin-right: 40px;
            cursor: default;
            color: rgb(137, 137, 137);;
            
            @media screen and (max-width: 768px) {
                margin-right: 0px;
            }
            
            &:hover {
                text-shadow: 0px 0px 10px white;
            }
        }
    }
`;

export default function MainComp () {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const isUserLogin = useSelector(state => state.user.isUserLogin);
    const webImg = useSelector(state => state.user.webImg);
    // const cartItems = useSelector(state => state.user.cartItems);
    const [ items, setItems ] = useState([]); 
    const [ isContentsOpen, setIsContentsOpen ] = useState(null);
    const [ contentHeight, setContentHeight ] = useState(0);
    const [ cartItems, setCartItems ] = useState([]);
 
    const loginRef = useRef();
    const cartRef = useRef();
    const itemRef = useRef();

    const getAllInfos = async () => {
        // 유저의 정보를 가져옵니다. 로그인을 했다면 유저 정보를, 하지 않았다면 에러를 반환합니다.
        // cart 졍보도 가져오니 지우지 마세요.
        await axios.get(
            `${process.env.REACT_APP_URL}/user/verify`,
            {
                "Content-Type": "application/json",
                withCredentials: true,
            }
        )
        .then((res) => {
            console.log(res)
            // dispatch(setCartItems(res.data.items));
            setCartItems(res.data.items);
        })
        .catch((err) => {
            console.log(err);
        })

        // 모든 아이템 리스트를 가져옵니다.
        const itemsInfo = await axios.post(
            `${process.env.REACT_APP_URL}/items/itemReq`,
            { id: null },
            { 
                "Content-Type": "application/json",
                withCredentials: true, 
            },
        )
        .then((res) => {
            console.log(res.data);
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        }) 
        console.log(itemsInfo);
        // dispatch(setItems(itemsInfo));
        if(itemsInfo.length > 0) { setItems(itemsInfo) }
        if(itemsInfo.length === 0) { setItems([]) }
    }

    const contentHandler = (el) => {
        if(el === isContentsOpen && el !== "cart-refresh") {
            setIsContentsOpen("block");
        }
        else {
            setIsContentsOpen(el);
        }
        if(el === "cart" || el === "cart-refresh") {
            setContentHeight(cartRef.current.clientHeight);
        }
        else if(el === "login") {
            setContentHeight(loginRef.current.clientHeight);
        }
        else if(el === "item") {
            setContentHeight(itemRef.current.clientHeight);
        }
        else if(el === "signup-open" || el === "signup-close") {
            setContentHeight(loginRef.current.clientHeight);
        }
    }

    const swiperElRef = useRef();

    useEffect(() => {
        getAllInfos();
    }, []);

    return (
        <Frame>
            <MainCompContainer>
                <div id="item_list">
                    {
                        items.map((item, index) => {
                            return (
                                <div key={index}>
                                    <img
                                    src={item.attributes.itemNameImg} 
                                    onClick={() => {  navigate(`/itempage/${item.id}`) }} 
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                {
                    cartItems.length > 0 &&
                    <div id="cart-wrapper" onClick={()=>{contentHandler("cart")}}>
                        <img src={cartImg} />
                    </div>
                }
                <ContentBoxWrapper id="cart" content_display={isContentsOpen} content_height={contentHeight}>
                    <ContentBox ref={cartRef}>
                        <Cart 
                        cartItems={cartItems} 
                        setCartItems={setCartItems}
                        contentHandler={contentHandler}
                        />
                    </ContentBox>
                </ContentBoxWrapper>
            </MainCompContainer>
            <Footer>
                <div id="info">
                    <div id="comp-name">© Baitnine</div>
                    <div id="enter-info">
                        베이트나인 / 사업자등록번호: 637-06-02423 / 대표: 이윤성 / 충청남도 예산군 예산읍 벚꽃로 175-17 106동 1101 / 성덕정 19길 12 2층 / 010-5370-7239
                    </div>
                </div>
                <div id="terms">
                    <a href="/contact">contact</a> 
                    <a href="/terms/returns">returns</a>
                    <a href="/terms/shipping">shipping</a>
                    <a href="/terms/privacy">privacy policy</a>
                    <a href="/terms/use">terms of use</a>
                </div>
            </Footer>
        </Frame>
    )
}