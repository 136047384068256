import styled from 'styled-components';
import axios from 'axios';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    
    /* > div#logo {
        width: 400px;
        height: 160px;
        animation: rotate 4s ease-in-out infinite;

        > div#logobox {
            width: 400px;
            height: 80px;
            
            > img {
                width: 100%;
                height: 100%;
            }
        }

        > div#shadow {
            width: 400px;
            height: 80px;
            transform: scaleY(-1);
    
            > img {
                width: 100%;
                height: 100%;
            }
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))
        }

        @keyframes rotate {
            0% {
                transform: scaleX(0);
            }
            25% {
                transform: scaleX(1);
            }
            50% {
                transform: scaleX(0);
            }
            75% {
                transform: scaleX(-1);
            }
            100% {
                transform: scaleX(0);
            }
        }
    } */

    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    > div#home-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 20px;
        border: 1px solid black;
        border-radius: 2px;
        margin-top: 20px;

        &:hover {
            cursor: default;
            opacity: 0.5;
        }

        &:active {
            opacity: 1;
        }
    }
`;

export default function TossSuccess() {
    const navigate = useNavigate();

    return (
        // 성공시 주문 정보, 실패시 에러 메시지
        <SuccessPage> 
            {/* <div id="logo">
                <div id="logobox">
                    <img src={LogoWhite} alt="logo" />
                </div>
                <div id="shadow">
                    <img src={LogoWhite} alt="logo" />
                </div>
            </div> */}
            
            <div>
                <h3>PAYMENT FAILED</h3>
                <div>please try agin</div>
            </div>
            
            <div id="home-button" onClick={() => {navigate("/")}}>home</div>

        </SuccessPage>
    )
}