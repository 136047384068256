import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { register } from 'swiper/element/bundle';
import "swiper/element/css/grid";

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: white; */
    
    @media screen and (max-width: 768px) {
        /* position: absolute; */
        width: 100%;
        /* height: 100%; */
        /* height: 100%; */
    }

    > swiper-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 768px) {
            /* position: absolute; */
            width: 100%;
            /* height: 100%; */
            /* height: 100%; */
        }
    
        > swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            @media screen and (max-width: 768px) {
                /* position: absolute; */
                width: 100%;
                /* height: 100%; */
                /* width: 300px;
                height: 300px; */

            }
    
            > img {
                /* width: auto; */
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                @media screen and (max-width: 768px) {
                    /* position: absolute; */
                    /* width: 300px;
                    height: 300px; */
                    width: 100%;
                    /* height: 100%; */
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
`;

export default function ImgSlider ({ 
    photos,
    id,
}) {
    console.log("image slider render")
    register();
    const swiperElRef = useRef(null);

    useEffect(() => {
        console.log("image slider useEffect")
        swiperElRef.current.swiper.slideTo(0);
    }, [id]);

    return (
        <Frame>
            <swiper-container
                style={{
                    '--swiper-navigation-color': 'gray',
                    '--swiper-pagination-color': 'gray',
                    '--swiper-navigation-size': '20px',
                    '--swiper-pagination-size': '20px',
                }}
                className={`swiper-container`}
                ref={swiperElRef}
                // lazy="true"
                pagination={{ clickable: true }}
                // pagination="true"
                navigation="true"
                // loop="true"
                // centeredSlides="true"
                // breakpoints={{
                //     768: {
                        
                //     },
                // }}
            >
                {
                    photos.map((el, index) => {
                        return (
                        <swiper-slide key={index}>
                            <img 
                            src={el.attributes.path}
                            alt={`Item ${id} Image ${index + 1}`}
                            // onLoad={() => { console.log("loaded") }}
                            />
                        </swiper-slide>
                        )
                    })
                }
            </swiper-container>
        </Frame>
    )
}