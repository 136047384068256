import styled from 'styled-components';
import axios from 'axios';
import qs from 'qs';
import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    setShippingCharge,
    setLastName,
    setFirstName,
    setEmail,
    setPhone,
    setCountry,
    setPostalCode,
    setProvince,
    setAddress,
    setCity,
    setApartment,
    setSelectedShippingMethod,
} from '../redux/rootSlice';
import LoadingComp from './loading';
import Toss from './toss';
import PayPalButton from './paypalButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const InformationCheckoutComp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: space-around; */
    width: 100%;
    height: auto;
    
    > h2 {
        font-weight: lighter;
        color: rgb(68,68,68);
    }  
`;

const InfoBox = styled.div`
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    /* border: 1px solid purple; */
    margin-bottom: 10px;
    
    > input {
        width: 100%;
        height: 90%;
        border: 1px solid lightgray;
        outline: none;

        &:hover {
            border: 1px solid rgb(25,144,198);
        }
        
        &:active {
            border: 1px solid rgb(18,97,134);
        }

        &:focus::placeholder {
            color: rgb(25,144,198);
        }
    }
    
    > select option[value="select"][disabled] {
        display: none;
    }

    > select {
        width: 100%;
        height: 100%;

        &:hover {
            border: 1px solid rgb(25,144,198);
        }
    }
`;

const AddressSearchBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    min-height: 40px;
    margin-bottom: 10px;
    
    > div.asb-child {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40px;
        /* display: none; */

        > input {
            width: 100%;
            height: 90%;
            border: 1px solid lightgray;
        }
    
        > div#address-search {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 95%;
            border: 1px solid lightgray;
            color: gray;
    
            :hover {
                color: lightgray;
            }
    
            :active {
                color: gray;
            }
        }
    }
`;

const AddressResultBox = styled.div`
    display: ${props => props.addressValData !== null? "flex": "none"};
    flex-direction: column;
    align-items: flex-start;
    width: 99%;
    height: auto;
    max-height: 400px;
    border: 1px solid gray;
    transition: height 1s;
    overflow-y: scroll;
    
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        border-bottom: 1px solid gray;
        font: 15px 'Nanum Gothic', sans-serif;
        color: rgb(68,68,68);

        :hover {
            /* color: rgb(25,144,198); */
            color: black;
            background-color: lightgray;
            /* background-color: rgb(105,194,236); */
        }

        :active {
            opacity: 0.5;
        }

        > div#no-address {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 99%;
            min-height: 40px;
        }

        > div#postalcode-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            margin-right: 5px;
        }

        > div#addressbox {
            display: flex;
            flex-direction: column;
            width: 100%;
            /* border: 1px solid blue; */
            margin-top: 5px;
            margin-bottom: 5px;

            > div.address {
                display: flex;
                align-items: center;
                /* justify-content: center; */
            }
        }
    }
`;

const AddressSelectBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    min-height: 40px;
    margin-bottom: 10px; 
    cursor: default;

    > div.address-option {
        display: flex;
        /* flex-direction: row; */
        align-items: center;
        justify-content: center;
        width: 99%;
        min-height: 40px;
        height: auto;
        border: 1px solid lightgray;
        font-size: 14px;

        :hover {
            /* opacity: 0.6; */
            background-color: lightgray;
            color: black;
        }

        :active {
            opacity: 0.5;
        }
    }
`;

const Errormessage = styled.div`
    width: 100%;
    max-height: ${props => props.postalErrMessage? '100px' : '0px'};
    transition: height 0.5s;
    /* background-color: green; */
    color: red;
`;

const ShippingMethods = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    /* border: 1px solid lightgray; */
    border-radius: 2px;
    margin-bottom: 10px;
    position: relative;

    > div#loadingBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
    }

    > button {
        width: 100%;
        height: 50px;
        border: 1px solid black;
        background-color: white;
        font-weight: lighter;
        font-size: 14px;
        border-radius: 5px;
        /* margin-top: 10px; */

        &:hover {
            /* opacity: 0.6; */
            background-color: rgb(240,240,240);
        }

        &:active {
            opacity: 0.5;
        }
    }

    > div:nth-child(${props => props.ssm >= 0? props.ssm+1 : null}) {
        color: rgb(25,144,198);
        border-color: rgb(25,144,198);
    }

`;

const ShippingMethod = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    border: 1px solid lightgray;
    color: gray;
    cursor: default;

    &:hover {
        color: rgb(25,144,198);
        border-color: rgb(25,144,198);
    }

    &:active {
        color: rgb(18,97,134);
    }

    > div#serviceType {
        margin-left: 10px;
    }

    > div#serviceCost {
        margin-right: 10px;
    }
`;

const PaymentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    /* width: 200px; */
    height: auto;
    /* border: 1px solid red; */
    margin-bottom: 200px;
`;

const ShippingMethodLoading = styled.div`
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    top: 0px;
    background-color: rgba(224,224,224,0.2);
`;

export default function InfomationCheckout () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const country = useSelector(state => state.user.country);
    const shippingCharge = useSelector(state => state.user.shippingCharge);
    const address = useSelector(state => state.user.address);
    const apartment = useSelector(state => state.user.apartment);
    // const city = useSelector(state => state.user.city);
    // const province = useSelector(state => state.user.province);
    const postalCode = useSelector(state => state.user.postalCode);
    // const phone = useSelector(state => state.user.phone);
    // const firstName = useSelector(state => state.user.firstName);
    // const lastName = useSelector(state => state.user.lastName);
    // const email = useSelector(state => state.user.email);
    // const userAddressList = useSelector(state => state.user.userAddressList);
    const selectedShippingMethod = useSelector(state => state.user.selectedShippingMethod);

    const emailRef = useRef();
    // const [ country, setCountry ] = useState("");
    const lastNameRef = useRef();
    const firstNameRef = useRef();
    const postalCodeRef = useRef();
    const provinceRef = useRef();
    const cityRef = useRef();
    const adressRef = useRef();
    const apartmentRef = useRef();
    const addressRef = useRef();
    const apartmentRefKr = useRef();
    const phoneRef = useRef();
    const countryRef = useRef();

    const [ shippingMethod, setShippingMethod ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isShippingMethodLoading, setIsShippingMethodLoading ] = useState(false);
    const [ selectedShippingMethodIndex, setSelectedShippingMethodIndex ] = useState(null);
    const [ shippingButtonBlock, setShippingButtonBlock ] = useState(false); // shippingButtonBlock은 국가를 한국으로 바꿀때 rerendering되는 사이 클릭하지 못하게 막는 역할을 합니다.
    const [ postalErrMessage, setPostalErrMessage ] = useState(""); // getDeliveryInfo 요청시 에러를 받으면 에러메세지를 띄워줍니다.
    const [ addressValData, setAddressValData ] = useState(null);
    // const [ checkoutInfos, setCheckoutInfos ] = useState([]);

    // const [ countryValue, setCountryValue ] = useState("select");
    const getFedexToken = () => {
        axios.get(
            `${process.env.REACT_APP_URL}/delivery/getFedexToken`,
            {
                withCredentials: true,
                sameSite: 'none',
            }
        )
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleInformation = async() => {
        console.log("handleInformation")

        // if(email === "" || lastName === "" || firstName === "" || province === "" || postalCode === "" || city === "" || adress === "" || phone === "") {
        //     if(email === "") {
        //         emailRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(country === "") {
        //         countryRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(lastName === "") {
        //         lastNameRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(firstName === "") {
        //         firstNameRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(postalCode === "") {
        //         postalCodeRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(province === "") {
        //         provinceRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(city === "") {
        //         cityRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(adress === "") {
        //         adressRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        //     if(phone === "") {
        //         phoneRef.current.style.border = "1px solid rgb(254,87,87)";
        //     }
        // }
        // else {
            setIsLoading(true); // 로딩창 띄우기

            const addressInfo = {
                country: country,
                postalCode: postalCode,
                address: address,
                apartment: apartment,
            }
            console.log(addressInfo)

            await getFedexToken();

            await axios.post(
                `${process.env.REACT_APP_URL}/delivery/getDeliveryInfo`,
                {
                    data: addressInfo,
                },
                {
                    withCredentials: true,
                    sameSite: 'none',
                }
            )
            .then((res) => {
                console.log(res)
                if(res.data.message === "reissue token") { navigate("/") }
                setShippingMethod(res.data);
                setPostalErrMessage("");
                postalCodeRef.current.style.border = "1px solid lightgray";
                provinceRef.current.style.border = "1px solid lightgray";
                cityRef.current.style.border = "1px solid lightgray";
                addressRef.current.style.border = "1px solid lightgray";
                apartmentRef.current.style.border = "1px solid lightgray";
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err)
                if(err.code === "ERR_BAD_REQUEST") {
                    setPostalErrMessage("server error please try again later");
                }
                if(err.response.data === "SYSTEM.UNEXPECTED.ERROR") {
                    setPostalErrMessage("Server error. Please try again later")
                }
                if(err.response.data === "postal code error") {
                    setPostalErrMessage("Postal code error")
                    postalCodeRef.current.style.border = "1px solid rgb(254,87,87)";
                    provinceRef.current.style.border = "1px solid lightgray";
                    cityRef.current.style.border = "1px solid lightgray";
                    addressRef.current.style.border = "1px solid lightgray";
                    apartmentRef.current.style.border = "1px solid lightgray";
                }
                // else {
                    // }
                if(err.response.data === "unserviceable area") {
                    setPostalErrMessage("Sorry, we do not ship to this area. Please contact us for more information.")
                    postalCodeRef.current.style.border = "1px solid lightgray";
                    provinceRef.current.style.border = "1px solid rgb(254,87,87)";
                    cityRef.current.style.border = "1px solid rgb(254,87,87)";
                    addressRef.current.style.border = "1px solid rgb(254,87,87)";
                    apartmentRef.current.style.border = "1px solid rgb(254,87,87)";
                }
            })

            setIsLoading(false); // 로딩창 끄기
        // }
        console.log(shippingMethod)
    }

    const borderHandler = async() => {
        // postalCodeRef.current.style.border = "1px solid lightgray"
        // provinceRef.current.style.border = "1px solid lightgray"
        // cityRef.current.style.border = "1px solid lightgray"
        // addressRef.current.style.border = "1px solid lightgray"
        // apartmentRef.current.style.border = "1px solid lightgray"

        // setShippingCharge(0);
        // dispatch(setShippingCharge(0));
        // await axios.post(
        //     `${process.env.REACT_APP_URL}/delivery/updateShippingMethod`,
        //     {
        //         shippingMethod: null,
        //     },
        //     {
        //         withCredentials: true,
        //         sameSite: 'none',
        //     }
        // )
        // .then((res) => {
        //     console.log(res);
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
    }

    const updateShippingMethod = async(method, index) => {
        // dispatch(setShippingCharge(shippingMethod[el].ratedShipmentDetails[0].totalNetChargeWithDutiesAndTaxes))
        // setShippingCharge(shippingMethod[el].ratedShipmentDetails[0].totalNetChargeWithDutiesAndTaxes);
        // setIsShippingMethodLoading(true);
        setSelectedShippingMethodIndex(index);
        dispatch(setSelectedShippingMethod(method));
        dispatch(setShippingCharge(method.ratedShipmentDetails[0].totalNetChargeWithDutiesAndTaxes))
        await getFedexToken();
        await axios.post(
            `${process.env.REACT_APP_URL}/delivery/updateShippingMethod`,
            {
                shippingMethod: method,
                postalCode: postalCode,
                country: country,
            },
            {
                withCredentials: true,
                sameSite: 'none',
            }
        )
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);

        })
        setIsShippingMethodLoading(false);
    }

    const addressCheckHandler = async() => {
        // setAddressDropdown(true);
        console.log(address)
        if(!country) {
            alert("Please select your country");
            return;
        }
        await axios.post(
            `${process.env.REACT_APP_URL}/delivery/addressValidation`,
            {
                country: country,
                data: address,
            },
            {
                withCredentials: true,
                sameSite: 'none',
            }
        )
        .then((res)=> {
            if(res.data.common.errorMessage !== "정상") {
                console.log("error")
                setAddressValData([]);
                return;
            }
            setAddressValData(res.data.juso);
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const cdataHandler = (postalCode, address) => {
        addressRef.current.value = address;
        postalCodeRef.current.value = postalCode;
        setAddressValData(null);
        dispatch(setPostalCode(postalCode));
        dispatch(setAddress(address));
    }

    // const getUserAddress = async() => {
    //     firstNameRef.current.value = firstName;
    //     lastNameRef.current.value = lastName;
    //     phoneRef.current.value = phone;
    //     emailRef.current.value = email;
    // };

    // const selectAddressHandler = async(el) => {
    //     console.log(el)
    //     await dispatch(setCountry(el.country));
    //     // setCountryValue(el.country);
    //     postalCodeRef.current.value = el.postalCode;
    //     if(el.country === "KR") {
    //         addressRef.current.value = el.address;
    //         apartmentRefKr.current.value = el.apartment;
    //     }
    //     else {
    //         adressRef.current.value = el.address;
    //         provinceRef.current.value = el.state;
    //         cityRef.current.value = el.city;
    //         apartmentRef.current.value = el.apartment;
    //     }
    //     dispatch(setCountry(el.country));
    //     dispatch(setPostalCode(el.postalCode));
    //     dispatch(setAddress(el.address));
    //     dispatch(setApartment(el.apartment));
    //     dispatch(setProvince(el.state));
    //     dispatch(setCity(el.city));
    // }

    useEffect(() => {
        // 유저 주소 목록 불러오기
        // console.log(lastName)
        // console.log(firstName)
        // console.log(email)
        // console.log(country)
        // getUserAddress();

        if(country === "KR"){
            setShippingButtonBlock(true);
            console.log("country changed to KR")
            return
        }
        if(country !== "KR"){
            setShippingButtonBlock(false);
            console.log("country changed to Else")
            return;
        }
    }, [country])

    const stateHandler = (e, type) => {
        if(type === "postalcode" || type === "province" || type === "city" || type === "address" || type === "apartment") {
            if(shippingCharge !== 0) { dispatch(setShippingCharge(0)); };
        }
        if(type === "lastname") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setLastName(e.target.value));
            return;
        }
        if(type === "firstname") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setFirstName(e.target.value));
            return;
        }
        if(type === "email") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setEmail(e.target.value));
            return;
        }
        if(type === "phone") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setPhone(e.target.value));
            return;
        }
        if(type === "country") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setCountry(e.target.value));
            return;
        }
        if(type === "postalcode") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setPostalCode(e.target.value));
            return;
        }
        if(type === "province") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setProvince(e.target.value));
            return;
        }
        if(type === "city") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setCity(e.target.value));
            return;
        }
        if(type === "address") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setAddress(e.target.value));
            return;
        }
        if(type === "apartment") {
            e.target.style.border = "1px solid lightgray";
            dispatch(setApartment(e.target.value));
            return;
        }

    }

    return (
        <InformationCheckoutComp onChange={() => {setShippingMethod([])}}>
            <h2>
                Contact Information
            </h2>
            <InfoBox id="name">
                <input placeholder="Last name" ref={lastNameRef} onChange={(e) => { stateHandler(e, "lastname") }}></input>
                <input placeholder="First name" ref={firstNameRef} onChange={(e) => { stateHandler(e, "firstname") }}></input>
            </InfoBox>
            <InfoBox id="emailbox">
                <input id="email" placeholder="Email" ref={emailRef} onChange={(e) => { stateHandler(e, "email") }}></input>
            </InfoBox>
            <InfoBox id="phone">
                <input placeholder="Phone" ref={phoneRef} onChange={(e) => { stateHandler(e, "phone") }}></input>
            </InfoBox>
            
            <h2>
                Shipping Address
            </h2>

            {/* {
                userAddressList && Object.keys(userAddressList).length > 0?
                <AddressSelectBox>
                    {
                        userAddressList.map((el, index) => {
                            return (
                                <div key={index} className="address-option" onClick={() => selectAddressHandler(el)}>
                                    <span>{el.country}</span>
                                    <span>{el.state? `${el.state}, `: null}</span>
                                    <span>{el.city? `${el.city}, `: null}</span>
                                    <span>{el.address}</span>
                                    <span>{el.apartment}</span>
                                    <span>[{el.postalCode}]</span>
                                </div>
                            )
                        })
                    }
                </AddressSelectBox>:
                null
            } */}

            <InfoBox id="countrySelectorWrapper" ref={countryRef} >
                <select value={country} onChange={ (e)=>{stateHandler(e, "country")} }>
                    <option value="select" disabled selected>Country</option>
                    // Afghanistan	AF
                    <option value="AF" contry="Afghanistan">Afghanistan</option>
                    // Albania	AL
                    <option value="AL" contry="Albania">Albania</option>
                    // Algeria	DZ
                    <option value="DZ" contry="Algeria">Algeria</option>
                    // American Samoa	AS
                    <option value="AS" contry="American Samoa">American Samoa</option>
                    // Andorra	AD
                    <option value="AD" contry="Andorra">Andorra</option>
                    // Angola	AO
                    <option value="AO" contry="Angola">Angola</option>
                    // Anguilla	AI
                    <option value="AI" contry="Anguilla">Anguilla</option>
                    // Antarctica	AQ
                    <option value="AQ" contry="Antarctica">Antarctica</option>
                    // Antigua, Barbuda	AG
                    <option value="AG" contry="Antigua, Barbuda">Antigua, Barbuda</option>
                    // Argentina	AR
                    <option value="AR" contry="Argentina">Argentina</option>
                    // Armenia	AM
                    <option value="AM" contry="Armenia">Armenia</option>
                    // Aruba	AW
                    <option value="AW" contry="Aruba">Aruba</option>
                    // Australia	AU
                    <option value="AU" contry="Australia">Australia</option>
                    // Austria	AT
                    <option value="AT" contry="Austria">Austria</option>
                    // Azerbaijan	AZ
                    <option value="AZ" contry="Azerbaijan">Azerbaijan</option>
                    // Bahamas	BS
                    <option value="BS" contry="Bahamas">Bahamas</option>
                    // Bahrain	BH
                    <option value="BH" contry="Bahrain">Bahrain</option>
                    // Bangladesh	BD
                    <option value="BD" contry="Bangladesh">Bangladesh</option>
                    // Barbados	BB
                    <option value="BB" contry="Barbados">Barbados</option>
                    // Belarus	BY
                    <option value="BY" contry="Belarus">Belarus</option>
                    // Belgium	BE
                    <option value="BE" contry="Belgium">Belgium</option>
                    // Belize	BZ
                    <option value="BZ" contry="Belize">Belize</option>
                    // Benin	BJ
                    <option value="BJ" contry="Benin">Benin</option>
                    // Bermuda	BM
                    <option value="BM" contry="Bermuda">Bermuda</option>
                    // Bhutan	BT
                    <option value="BT" contry="Bhutan">Bhutan</option>
                    // Bolivia	BO
                    <option value="BO" contry="Bolivia">Bolivia</option>
                    // Bonaire, Caribbean Netherlands, Saba, St. Eustatius	BQ
                    <option value="BQ" contry="Bonaire, Caribbean Netherlands, Saba, St. Eustatius">Bonaire, Caribbean Netherlands, Saba, St. Eustatius</option>
                    // Bosnia-Herzegovina	BA
                    <option value="BA" contry="Bosnia-Herzegovina">Bosnia-Herzegovina</option>
                    // Botswana	BW
                    <option value="BW" contry="Botswana">Botswana</option>
                    // Bouvet Island	BV
                    <option value="BV" contry="Bouvet Island">Bouvet Island</option>
                    // Brazil	BR
                    <option value="BR" contry="Brazil">Brazil</option>
                    // British Indian Ocean Territory	IO
                    <option value="IO" contry="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    // Brunei	BN
                    <option value="BN" contry="Brunei">Brunei</option>
                    // Bulgaria	BG
                    <option value="BG" contry="Bulgaria">Bulgaria</option>
                    // Burkina Faso	BF
                    <option value="BF" contry="Burkina Faso">Burkina Faso</option>
                    // Burundi	BI
                    <option value="BI" contry="Burundi">Burundi</option>
                    // Cambodia	KH
                    <option value="KH" contry="Cambodia">Cambodia</option>
                    // Cameroon	CM
                    <option value="CM" contry="Cameroon">Cameroon</option>
                    // Canada	CA
                    <option value="CA" contry="Canada">Canada</option>
                    // Cape Verde	CV
                    <option value="CV" contry="Cape Verde">Cape Verde</option>
                    // Central African Republic	CF
                    <option value="CF" contry="Central African Republic">Central African Republic</option>
                    // Chad	TD
                    <option value="TD" contry="Chad">Chad</option>
                    // Chile	CL
                    <option value="CL" contry="Chile">Chile</option>
                    // China	CN
                    <option value="CN" contry="China">China</option>
                    // Christmas Island	CX
                    <option value="CX" contry="Christmas Island">Christmas Island</option>
                    // Cocos (Keeling) Islands	CC
                    <option value="CC" contry="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    // Colombia	CO
                    <option value="CO" contry="Colombia">Colombia</option>
                    // Comoros	KM
                    <option value="KM" contry="Comoros">Comoros</option>
                    // Congo	CG
                    <option value="CG" contry="Congo">Congo</option>
                    // Congo, Democratic Republic Of	CD
                    <option value="CD" contry="Congo, Democratic Republic Of">Congo, Democratic Republic Of</option>
                    // Cook Islands	CK
                    <option value="CK" contry="Cook Islands">Cook Islands</option>
                    // Costa Rica	CR
                    <option value="CR" contry="Costa Rica">Costa Rica</option>
                    // Croatia	HR
                    <option value="HR" contry="Croatia">Croatia</option>
                    // Cuba	CU
                    <option value="CU" contry="Cuba">Cuba</option>
                    // Curacao	CW
                    <option value="CW" contry="Curacao">Curacao</option>
                    // Cyprus	CY
                    <option value="CY" contry="Cyprus">Cyprus</option>
                    // Czech Republic	CZ
                    <option value="CZ" contry="Czech Republic">Czech Republic</option>
                    // Denmark	DK
                    <option value="DK" contry="Denmark">Denmark</option>
                    // Djibouti	DJ
                    <option value="DJ" contry="Djibouti">Djibouti</option>
                    // Dominica	DM
                    <option value="DM" contry="Dominica">Dominica</option>
                    // Dominican Republic	DO
                    <option value="DO" contry="Dominican Republic">Dominican Republic</option>
                    // East Timor	TL
                    <option value="TL" contry="East Timor">East Timor</option>
                    // Ecuador	EC
                    <option value="EC" contry="Ecuador">Ecuador</option>
                    // Egypt	EG
                    <option value="EG" contry="Egypt">Egypt</option>
                    // El Salvador	SV
                    <option value="SV" contry="El Salvador">El Salvador</option>
                    // England, Great Britain, Northern Ireland, Scotland, United Kingdom, Wales, Channel Islands	GB
                    <option value="GB" contry="England, Great Britain, Northern Ireland, Scotland, United Kingdom, Wales, Channel Islands">England, Great Britain, Northern Ireland, Scotland, United Kingdom, Wales, Channel Islands</option>
                    // Equatorial Guinea	GQ
                    <option value="GQ" contry="Equatorial Guinea">Equatorial Guinea</option>
                    // Eritrea	ER
                    <option value="ER" contry="Eritrea">Eritrea</option>
                    // Estonia	EE
                    <option value="EE" contry="Estonia">Estonia</option>
                    // Eswatini	SZ
                    <option value="SZ" contry="Eswatini">Eswatini</option>
                    // Ethiopia	ET
                    <option value="ET" contry="Ethiopia">Ethiopia</option>
                    // Faeroe Islands	FO
                    <option value="FO" contry="Faeroe Islands">Faeroe Islands</option>
                    // Falkland Islands	FK
                    <option value="FK" contry="Falkland Islands">Falkland Islands</option>
                    // Fiji	FJ
                    <option value="FJ" contry="Fiji">Fiji</option>
                    // Finland	FI
                    <option value="FI" contry="Finland">Finland</option>
                    // France	FR
                    <option value="FR" contry="France">France</option>
                    // French Guiana	GF
                    <option value="GF" contry="French Guiana">French Guiana</option>
                    // French Southern Territories	TF
                    <option value="TF" contry="French Southern Territories">French Southern Territories</option>
                    // Gabon	GA
                    <option value="GA" contry="Gabon">Gabon</option>
                    // Gambia	GM
                    <option value="GM" contry="Gambia">Gambia</option>
                    // Georgia	GE
                    <option value="GE" contry="Georgia">Georgia</option>
                    // Germany	DE
                    <option value="DE" contry="Germany">Germany</option>
                    // Ghana	GH
                    <option value="GH" contry="Ghana">Ghana</option>
                    // Gibraltar	GI
                    <option value="GI" contry="Gibraltar">Gibraltar</option>
                    // Grand Cayman, Cayman Islands	KY
                    <option value="KY" contry="Grand Cayman, Cayman Islands">Grand Cayman, Cayman Islands</option>
                    // Great Thatch Island, Great Tobago Islands, Jost Van Dyke Islands, Norman Island, Tortola Island, British Virgin Islands	VG
                    // Greece	GR
                    <option value="GR" contry="Greece">Greece</option>
                    // Greenland	GL
                    <option value="GL" contry="Greenland">Greenland</option>
                    // Grenada	GD
                    <option value="GD" contry="Grenada">Grenada</option>
                    // Guadeloupe	GP
                    <option value="GP" contry="Guadeloupe">Guadeloupe</option> 
                    // Guam	GU
                    <option value="GU" contry="Guam">Guam</option>
                    // Guatemala	GT
                    <option value="GT" contry="Guatemala">Guatemala</option>
                    // Guinea	GN
                    <option value="GN" contry="Guinea">Guinea</option>
                    // Guinea Bissau	GW
                    <option value="GW" contry="Guinea Bissau">Guinea Bissau</option>
                    // Guyana	GY
                    <option value="GY" contry="Guyana">Guyana</option>
                    // Haiti	HT
                    <option value="HT" contry="Haiti">Haiti</option>
                    // Heard and McDonald Islands	HM
                    <option value="HM" contry="Heard and McDonald Islands">Heard and McDonald Islands</option>
                    // Honduras	HN
                    <option value="HN" contry="Honduras">Honduras</option>
                    // Hong Kong	HK
                    <option value="HK" contry="Hong Kong">Hong Kong</option>
                    // Hungary	HU
                    <option value="HU" contry="Hungary">Hungary</option>
                    // Iceland	IS
                    <option value="IS" contry="Iceland">Iceland</option>
                    // India	IN
                    <option value="IN" contry="India">India</option>
                    // Indonesia	ID
                    <option value="ID" contry="Indonesia">Indonesia</option>
                    // Iran	IR
                    <option value="IR" contry="Iran">Iran</option>
                    // Iraq	IQ
                    <option value="IQ" contry="Iraq">Iraq</option>
                    // Ireland	IE
                    <option value="IE" contry="Ireland">Ireland</option>
                    // Israel	IL
                    <option value="IL" contry="Israel">Israel</option>
                    // Italy, Vatican City, San Marino	IT
                    <option value="IT" contry="Italy, Vatican City, San Marino">Italy, Vatican City, San Marino</option>
                    // Ivory Coast	CI
                    <option value="CI" contry="Ivory Coast">Ivory Coast</option>
                    // Jamaica	JM
                    <option value="JM" contry="Jamaica">Jamaica</option>
                    // Japan	JP
                    <option value="JP" contry="Japan">Japan</option>
                    // Jordan	JO
                    <option value="JO" contry="Jordan">Jordan</option>
                    // Kazakhstan	KZ
                    <option value="KZ" contry="Kazakhstan">Kazakhstan</option>
                    // Kenya	KE
                    <option value="KE" contry="Kenya">Kenya</option>
                    // Kiribati	KI
                    <option value="KI" contry="Kiribati">Kiribati</option>
                    // Kuwait	KW
                    <option value="KW" contry="Kuwait">Kuwait</option>
                    // Kyrgyzstan	KG
                    <option value="KG" contry="Kyrgyzstan">Kyrgyzstan</option>
                    // Laos	LA
                    <option value="LA" contry="Laos">Laos</option>
                    // Latvia	LV
                    <option value="LV" contry="Latvia">Latvia</option>
                    // Lebanon	LB
                    <option value="LB" contry="Lebanon">Lebanon</option>
                    // Lesotho	LS
                    <option value="LS" contry="Lesotho">Lesotho</option>
                    // Liberia	LR
                    <option value="LR" contry="Liberia">Liberia</option>
                    // Libya	LY
                    <option value="LY" contry="Libya">Libya</option>
                    // Liechtenstein	LI
                    <option value="LI" contry="Liechtenstein">Liechtenstein</option>
                    // Lithuania	LT
                    <option value="LT" contry="Lithuania">Lithuania</option>
                    // Luxembourg	LU
                    <option value="LU" contry="Luxembourg">Luxembourg</option>
                    // Macau	MO
                    <option value="MO" contry="Macau">Macau</option>
                    // Macedonia	MK
                    <option value="MK" contry="Macedonia">Macedonia</option>
                    // Madagascar	MG
                    <option value="MG" contry="Madagascar">Madagascar</option>
                    // Malawi	MW
                    <option value="MW" contry="Malawi">Malawi</option>
                    // Malaysia	MY
                    <option value="MY" contry="Malaysia">Malaysia</option>
                    // Maldives	MV
                    <option value="MV" contry="Maldives">Maldives</option>
                    // Mali	ML
                    <option value="ML" contry="Mali">Mali</option>
                    // Malta	MT
                    <option value="MT" contry="Malta">Malta</option>
                    // Marshall Islands	MH
                    <option value="MH" contry="Marshall Islands">Marshall Islands</option>
                    // Martinique	MQ
                    <option value="MQ" contry="Martinique">Martinique</option>
                    // Mauritania	MR
                    <option value="MR" contry="Mauritania">Mauritania</option>
                    // Mauritius	MU
                    <option value="MU" contry="Mauritius">Mauritius</option>
                    // Mayotte	YT
                    <option value="YT" contry="Mayotte">Mayotte</option>
                    // Mexico	MX
                    <option value="MX" contry="Mexico">Mexico</option>
                    // Micronesia	FM
                    <option value="FM" contry="Micronesia">Micronesia</option>
                    // Moldova	MD
                    <option value="MD" contry="Moldova">Moldova</option>
                    // Monaco	MC
                    <option value="MC" contry="Monaco">Monaco</option>
                    // Mongolia	MN
                    <option value="MN" contry="Mongolia">Mongolia</option>
                    // Montenegro	ME
                    <option value="ME" contry="Montenegro">Montenegro</option>
                    // Montserrat	MS
                    <option value="MS" contry="Montserrat">Montserrat</option>
                    // Morocco	MA
                    <option value="MA" contry="Morocco">Morocco</option>
                    // Mozambique	MZ
                    <option value="MZ" contry="Mozambique">Mozambique</option>
                    // Myanmar / Burma	MM
                    <option value="MM" contry="Myanmar / Burma">Myanmar / Burma</option>
                    // Namibia	NA
                    <option value="NA" contry="Namibia">Namibia</option>
                    // Nauru	NR
                    <option value="NR" contry="Nauru">Nauru</option>
                    // Nepal	NP
                    <option value="NP" contry="Nepal">Nepal</option>
                    // Netherlands, Holland	NL
                    <option value="NL" contry="Netherlands, Holland">Netherlands, Holland</option>
                    // New Caledonia	NC
                    <option value="NC" contry="New Caledonia">New Caledonia</option>
                    // New Zealand	NZ
                    <option value="NZ" contry="New Zealand">New Zealand</option>
                    // Nicaragua	NI
                    <option value="NI" contry="Nicaragua">Nicaragua</option>
                    // Niger	NE
                    <option value="NE" contry="Niger">Niger</option>
                    // Nigeria	NG
                    <option value="NG" contry="Nigeria">Nigeria</option>
                    // Niue	NU
                    <option value="NU" contry="Niue">Niue</option>
                    // Norfolk Island	NF
                    <option value="NF" contry="Norfolk Island">Norfolk Island</option>
                    // North Korea	KP
                    <option value="KP" contry="North Korea">North Korea</option>
                    // Northern Mariana Islands, Rota, Saipan, Tinian	MP
                    <option value="MP" contry="Northern Mariana Islands, Rota, Saipan, Tinian">Northern Mariana Islands, Rota, Saipan, Tinian</option>
                    // Norway	NO
                    <option value="NO" contry="Norway">Norway</option>
                    // Oman	OM
                    <option value="OM" contry="Oman">Oman</option>
                    // Pakistan	PK
                    <option value="PK" contry="Pakistan">Pakistan</option>
                    // Palau	PW
                    <option value="PW" contry="Palau">Palau</option>
                    // Palestine	PS
                    <option value="PS" contry="Palestine">Palestine</option>
                    // Panama	PA
                    <option value="PA" contry="Panama">Panama</option>
                    // Papua New Guinea	PG
                    <option value="PG" contry="Papua New Guinea">Papua New Guinea</option>
                    // Paraguay	PY
                    <option value="PY" contry="Paraguay">Paraguay</option>
                    // Peru	PE
                    <option value="PE" contry="Peru">Peru</option>
                    // Philippines	PH
                    <option value="PH" contry="Philippines">Philippines</option>
                    // Pitcairn	PN
                    <option value="PN" contry="Pitcairn">Pitcairn</option>
                    // Poland	PL
                    <option value="PL" contry="Poland">Poland</option>
                    // Portugal	PT
                    <option value="PT" contry="Portugal">Portugal</option>
                    // Puerto Rico	PR
                    <option value="PR" contry="Puerto Rico">Puerto Rico</option>
                    // Qatar	QA
                    <option value="QA" contry="Qatar">Qatar</option>
                    // Reunion	RE
                    <option value="RE" contry="Reunion">Reunion</option>
                    // Romania	RO
                    <option value="RO" contry="Romania">Romania</option>
                    // Russia	RU
                    <option value="RU" contry="Russia">Russia</option>
                    // Rwanda	RW
                    <option value="RW" contry="Rwanda">Rwanda</option>
                    // Samoa	WS
                    <option value="WS" contry="Samoa">Samoa</option>
                    // Sao Tome and Principe	ST
                    <option value="ST" contry="Sao Tome and Principe">Sao Tome and Principe</option>
                    // Saudi Arabia	SA
                    <option value="SA" contry="Saudi Arabia">Saudi Arabia</option>
                    // Senegal	SN
                    <option value="SN" contry="Senegal">Senegal</option>
                    // Serbia	RS
                    <option value="RS" contry="Serbia">Serbia</option>
                    // Seychelles	SC
                    <option value="SC" contry="Seychelles">Seychelles</option>
                    // Sierra Leone	SL
                    <option value="SL" contry="Sierra Leone">Sierra Leone</option>
                    // Singapore	SG
                    <option value="SG" contry="Singapore">Singapore</option>
                    // Slovak Republic	SK
                    <option value="SK" contry="Slovak Republic">Slovak Republic</option>
                    // Slovenia	SI
                    <option value="SI" contry="Slovenia">Slovenia</option>
                    // Solomon Islands	SB
                    <option value="SB" contry="Solomon Islands">Solomon Islands</option>
                    // Somalia	SO
                    <option value="SO" contry="Somalia">Somalia</option>
                    // South Africa	ZA
                    <option value="ZA" contry="South Africa">South Africa</option>
                    // South Georgia and South Sandwich Islands	GS
                    <option value="GS" contry="South Georgia and South Sandwich Islands">South Georgia and South Sandwich Islands</option>
                    // South Korea	KR
                    <option value="KR" contry="South Korea">South Korea</option>
                    // Spain, Canary Islands	ES
                    <option value="ES" contry="Spain, Canary Islands">Spain, Canary Islands</option>
                    // Sri Lanka	LK
                    <option value="LK" contry="Sri Lanka">Sri Lanka</option>
                    // St. Barthelemy	BL
                    <option value="BL" contry="St. Barthelemy">St. Barthelemy</option>
                    // St. Christopher, St. Kitts And Nevis	KN
                    <option value="KN" contry="St. Christopher, St. Kitts And Nevis">St. Christopher, St. Kitts And Nevis</option>
                    // St. John, St. Thomas, U.S. Virgin Islands, St. Croix Island	VI
                    <option value="VI" contry="St. John, St. Thomas, U.S. Virgin Islands, St. Croix Island">St. John, St. Thomas, U.S. Virgin Islands, St. Croix Island</option>
                    // St. Helena	SH
                    <option value="SH" contry="St. Helena">St. Helena</option>
                    // St. Lucia	LC
                    <option value="LC" contry="St. Lucia">St. Lucia</option>
                    // St. Maarten (Dutch Control)	SX
                    <option value="SX" contry="St. Maarten (Dutch Control)">St. Maarten (Dutch Control)</option>
                    // St. Martin (French Control)	MF
                    <option value="MF" contry="St. Martin (French Control)">St. Martin (French Control)</option>
                    // St. Pierre	PM
                    <option value="PM" contry="St. Pierre">St. Pierre</option>
                    // St. Vincent, Union Island	VC
                    <option value="VC" contry="St. Vincent, Union Island">St. Vincent, Union Island</option>
                    // Sudan	SD
                    <option value="SD" contry="Sudan">Sudan</option>
                    // Suriname	SR
                    <option value="SR" contry="Suriname">Suriname</option>
                    // Svalbard and Jan Mayen Island	SJ
                    <option value="SJ" contry="Svalbard and Jan Mayen Island">Svalbard and Jan Mayen Island</option>
                    // Sweden	SE
                    <option value="SE" contry="Sweden">Sweden</option>
                    // Switzerland	CH
                    <option value="CH" contry="Switzerland">Switzerland</option>
                    // Syria	SY
                    <option value="SY" contry="Syria">Syria</option>
                    // Tahiti, French Polynesia	PF
                    <option value="PF" contry="Tahiti, French Polynesia">Tahiti, French Polynesia</option>
                    // Taiwan	TW
                    <option value="TW" contry="Taiwan">Taiwan</option>
                    // Tajikistan	TJ
                    <option value="TJ" contry="Tajikistan">Tajikistan</option>
                    // Tanzania	TZ
                    <option value="TZ" contry="Tanzania">Tanzania</option>
                    // Thailand	TH
                    <option value="TH" contry="Thailand">Thailand</option>
                    // Togo	TG
                    <option value="TG" contry="Togo">Togo</option>
                    // Tokelau	TK
                    <option value="TK" contry="Tokelau">Tokelau</option>
                    // Tonga	TO
                    <option value="TO" contry="Tonga">Tonga</option>
                    // Trinidad and Tobago	TT
                    <option value="TT" contry="Trinidad and Tobago">Trinidad and Tobago</option>
                    // Tunisia	TN
                    <option value="TN" contry="Tunisia">Tunisia</option>
                    // Turkey	TR
                    <option value="TR" contry="Turkey">Turkey</option>
                    // Turkmenistan	TM
                    <option value="TM" contry="Turkmenistan">Turkmenistan</option>
                    // Turks and Caicos Islands	TC
                    <option value="TC" contry="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    // Tuvalu	TV
                    <option value="TV" contry="Tuvalu">Tuvalu</option>
                    // U.S. Minor Outlying Islands	UM
                    <option value="UM" contry="U.S. Minor Outlying Islands">U.S. Minor Outlying Islands</option>
                    // Uganda	UG
                    <option value="UG" contry="Uganda">Uganda</option>
                    // Ukraine	UA
                    <option value="UA" contry="Ukraine">Ukraine</option>
                    // United Arab Emirates	AE
                    <option value="AE" contry="United Arab Emirates">United Arab Emirates</option>
                    // United States	US
                    <option value="US" contry="United States">United States</option>
                    // Uruguay	UY
                    <option value="UY" contry="Uruguay">Uruguay</option>
                    // Uzbekistan	UZ
                    <option value="UZ" contry="Uzbekistan">Uzbekistan</option>
                    // Vanuatu	VU
                    <option value="VU" contry="Vanuatu">Vanuatu</option>
                    // Venezuela	VE
                    <option value="VE" contry="Venezuela">Venezuela</option>
                    // Vietnam	VN
                    <option value="VN" contry="Vietnam">Vietnam</option>
                    // Wallis and Futuna Islands	WF
                    <option value="WF" contry="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                    // Western Sahara	EH
                    <option value="EH" contry="Western Sahara">Western Sahara</option>
                    // Yemen	YE
                    <option value="YE" contry="Yemen">Yemen</option>
                    // Zambia	ZM
                    <option value="ZM" contry="Zambia">Zambia</option>
                    // Zimbabwe	ZW
                    <option value="ZW" contry="Zimbabwe">Zimbabwe</option>
                </select>
            </InfoBox>

            <InfoBox id="postalcode"> 
                <input 
                placeholder={country === "KR"? "우편번호": "Postal code / Zip code" }
                ref={postalCodeRef} 
                // onChange={() => {postalCodeRef.current.style.border = "1px solid lightgray"}}
                onChange={ (e) => { /*borderHandler();*/ stateHandler(e, "postalcode") } } //우편번호를 직접 입력시 작동
                ></input>
            </InfoBox>

            {
                country !== "KR"?
                <InfoBox id="province">
                    <input 
                    placeholder="province" 
                    ref={provinceRef}
                    onChange={ (e) => { /*borderHandler();*/ stateHandler(e, "province") } }
                    ></input>
                    <input 
                    placeholder="city" 
                    ref={cityRef}
                    onChange={ /*borderHandler*/ (e) => { stateHandler(e, "city") } }
                    ></input>
                </InfoBox>:
                null
            }

            {
                country !== "KR"?
                <InfoBox id="adress">
                    <input 
                    placeholder="Adress" 
                    ref={adressRef}
                    // onChange={() => {adressRef.current.style.border = "1px solid lightgray"}}
                    onChange={ (e) => { /*borderHandler();*/ stateHandler(e, "address") } }
                    ></input>
                </InfoBox>:
                null
            }

            {
                country !== "KR"?
                <InfoBox id="apartment">
                    <input
                    placeholder="Apartment, suite, etc. (optional)" 
                    ref={apartmentRef}
                    // onChange={() => {apartmentRef.current.style.border = "1px solid lightgray"}}
                    onChange={ (e) => { /*borderHandler();*/ stateHandler(e, "apartment") } }
                    ></input>
                </InfoBox>:
                null    
            }

            {
                country === "KR"?
                <AddressSearchBox> 
                    <div className="asb-child">
                        <input 
                        placeholder="우편번호 검색"
                        ref={addressRef}
                        onChange={(e) => { stateHandler(e, "address") }}
                        />
                        <div id="address-search" onClick={addressCheckHandler}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>
                    </div>
                    <AddressResultBox addressValData={addressValData}>
                        {
                            addressValData !== null? 
                            addressValData.length > 0?
                            addressValData.map((data, index) => {
                                const postalCode = data.zipNo;
                                const prevAddress = data.jibunAddr;
                                const newAddress = data.roadAddrPart1;
                                return (
                                    <div key={index} className="result" onClick={()=>{ cdataHandler(postalCode, newAddress) }}>
                                        <div id="postalcode-box">{postalCode}</div>
                                        <div id="addressbox">
                                            <div className="address">{prevAddress}</div>
                                            {
                                                newAddress?
                                                <div className="address">{newAddress}</div>:
                                                null
                                            }
                                        </div>
                                    </div>
                                )
                            }):
                            <div>
                                <div id="no-address">
                                    No address found
                                </div>
                            </div>:
                            null
                        }
                    </AddressResultBox>
                </AddressSearchBox>:
                null
            }

            { 
                country === "KR"?
                <InfoBox>
                    <input 
                    placeholder="상세주소"
                    ref={apartmentRefKr}
                    onChange={ (e) => { /*borderHandler();*/ stateHandler(e, "apartment") } }
                    />
                </InfoBox>:
                null
            }

            <Errormessage postalErrMessage={postalErrMessage}>
                {postalErrMessage}
            </Errormessage>
            {
                shippingButtonBlock?
                null:
                <h2>
                    Shipping Method
                </h2>
            }
            {
                shippingButtonBlock?
                null:
                shippingMethod.length === 0?
                isLoading?
                <ShippingMethods>
                    <LoadingComp />
                </ShippingMethods>:
                <ShippingMethods> 
                    <button onClick={handleInformation}> 
                        Get Shipping Method
                    </button>
                </ShippingMethods>:
                <ShippingMethods ssm={selectedShippingMethodIndex}>
                    {
                        shippingMethod.map((method, index) => {
                            return (
                                <ShippingMethod 
                                key={index} 
                                className="ssm" 
                                onClick={() => { 
                                    updateShippingMethod(method, index);
                                }}>
                                    <div id="serviceType">
                                        {method.serviceName}
                                    </div>
                                    <div id="serviceCost"> 
                                        {method.ratedShipmentDetails[0].totalNetChargeWithDutiesAndTaxes? 
                                        `$ ${method.ratedShipmentDetails[0].totalNetChargeWithDutiesAndTaxes}`: 
                                        null}
                                    </div>
                                </ShippingMethod>
                            )
                        })
                    }
                    {
                        isShippingMethodLoading? 
                        <ShippingMethodLoading>
                            <LoadingComp />
                        </ShippingMethodLoading>:
                        null
                    }
                </ShippingMethods>
            }
            <h2>
                Payment Method
            </h2>
            <PaymentBox>
                {
                    shippingButtonBlock?
                    <Toss/>:
                    <PayPalButton/>
                }
            </PaymentBox>
        </InformationCheckoutComp>
    )
}
