// checklist에서  import
import styled from "styled-components";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 45%;
    height: 100%;

    @media screen and (max-width: 768px) {
        /* max-width: ${props => props.itemToggleState? "1000px": "0%"}; */
        /* max-height: ${props => props.itemToggleState? "1000px": "auto"}; */
        align-items: center;
        width: 100%;
        height: auto;
        overflow: visible;
    }
`;

const Items = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    /* width: 45%; */
    width: 100%;
    height: 100%;
    background-color: rgba(221,221,221,0.5);

    /* @media screen and (max-width: 768px) {
        position: absolute;
        z-index: 2;
        opacity: 1;
        width: auto;
        height: 90vh;
        max-width: ${props => props.itemToggleState? "1000px": "0%"};
        overflow-x: scroll;
        background-color: rgba(221,221,221,1);
        left: 0px;
        transition: max-width 0.5s ease-in-out;
    } */
    @media screen and (max-width: 768px) {
        align-items: center;
        height: auto;
    }

    > div#order-summary {
        display: none;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            min-width: 280px;
            height: 50px;
            border-bottom: 1px solid lightgray;
            color: black;

            &:hover {
                > div:nth-child(1) {
                    filter: invert(50%);
                }
            }

            div:nth-child(1) {
                height: 100%;
                width: 110px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                
                /* :hover {    
                    cursor: pointer;
                    filter: invert(50%);
                } */
            }
        }
    }
`;

const Scrollbox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    /* min-width: 400px; */
    min-width: 300px;
    height: 80%;
    border-left: 1px solid lightgray;

    @media screen and (max-width: 768px) {
        width: 100%;
        overflow: hidden;
        max-height: ${props => props.itemToggleState? "1000px": "0px"};
        transition: max-height 0.4s ease;
    }

    > div#checkoutItemWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: space-between; */
        width: 90%;
        min-width: 280px;
        height: 100px;
        border-bottom: 1px solid lightgray;

        @media screen and (max-width: 768px) {
            
        }
        
        > div#checkoutItemImageWrapper {
            width: 80px;
            min-width: 80px;
            height: 80px;
            min-height: 80px;
            /* border: 1px solid lightgray; */
            /* margin-left: 5px; */

            > img {
                width: 100%;
                height: 100%;
            }
        }

        > div#checkoutItemInfo {
            /* border: 1px solid green; */
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            width: 100%;
            height: 60px;
            padding-left: 5px;
            margin-right: 5px;

            > div:nth-child(1) {
                font-size: 1.2rem;
                color: rgb(68,68,68);
            }

            > div:nth-child(2) {
                /* border: 1px solid blue; */
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                color: rgb(68,68,68);

                > div#sizeAndCount {
                    /* border: 1px solid black; */
                    color: gray;
                    font-family: "Malgun Gothic";
                    font-weight: 100;

                }
            }
        }
    }

    > div.calc {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        min-width: 280px;
        height: 50px;
        border-bottom: 1px solid lightgray;
        color: rgb(68,68,68);

        > label:nth-child(1) {
            margin-left: 5px;
        }

        > label:nth-child(2) {
            margin-right: 5px;
        }
    }
`

const ItemToggleButton = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        position: absolute;
        z-index: 2;
        left: 0px;
        width: 30px;
        height: 30px;
        opacity: 0.5;
    }
`;

export default function ItemsList() {
    const [ itemToggleState, setItemToggleState ] = useState(false);
    
    const cartItems = useSelector(state => state.user.cartItems);
    const shippingCharge = useSelector(state => state.user.shippingCharge);

    const [ subtotal, setSubtotal ] = useState(0);

    useEffect(() => {
        const st = cartItems.reduce((acc, cur) => {
            return acc + cur.price * cur.count
        }, 0)
        setSubtotal(st)
    }, [])

    return (
        <List>
            <Items>
                <div id="order-summary" onClick={() => { itemToggleState? setItemToggleState(false): setItemToggleState(true) }}>
                    <div>
                        <span>
                            order summary 
                        </span>  
                        {
                            itemToggleState?
                            <FontAwesomeIcon icon={faCaretUp} style={{margin: "4px 0px 0px 0px"}}/>:
                            <FontAwesomeIcon icon={faCaretDown} />
                        }
                    </div>
                    <span>₩ {subtotal}</span>
                </div>
                <Scrollbox itemToggleState={itemToggleState}>
                    {
                        cartItems.map((item, idx) => {
                            console.log(cartItems)
                            console.log(shippingCharge)
                            return (
                                <div key={idx} id="checkoutItemWrapper">
                                    <div id="checkoutItemImageWrapper">
                                        <img id="checkoutItemImgae" src={item.cartthumbnail}></img>
                                    </div>
                                    <div id="checkoutItemInfo">
                                        <div>
                                            {item.name}
                                        </div>
                                        <div>
                                            <div id="sizeAndCount">
                                                {item.selectedSize} / ✕{item.count}
                                            </div>
                                            ₩ {item.price * item.count}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="calc">
                        <label>subtotal</label>
                        <label>
                            ₩ {subtotal}
                        </label>
                    </div>
                    <div className="calc">
                        <label>shipping</label>
                        <label>
                            ${shippingCharge}
                        </label>
                    </div>
                    <div className="calc">
                        <label>total</label>
                        <label>
                            ₩ {(subtotal + shippingCharge).toFixed(2)}
                        </label>
                    </div>
                </Scrollbox>
            </Items>
        </List>
    )
}