import styled from 'styled-components';
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { useEffect, useState, useRef } from 'react';
import tossimage from '../image/TossPayments_Logo_Primary.jpg';
import axios from 'axios';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import masterCardLogo from '../image/mc_symbol_opt_45_3x.png';
// import {browserHistory} from 'react-router-dom';
// import { useNavigate } from 'react-router-dom'; 
import {
    PaymentWidgetInstance,
    loadPaymentWidget,
    ANONYMOUS,
} from "@tosspayments/payment-widget-sdk" 
import Loading from './loading';
import { useNavigate } from 'react-router-dom';

const TossComp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;

    > div {
        width: 100%;
        height: auto;
    }

    > div#paynow_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 99%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 5px;
        margin-top: 20px;

        > div#alert-message {
            color: red;
        }

        &:hover {
            cursor: default;
            color: rgb(25,144,198);
            border: 1px solid rgb(25,144,198);
        }
    }

    /* div#payment-method {
        position: absolute;
        z-index: 100;
        width: 600px;
        height: 800px;
    }

    div#agreement {
        position: absolute;
        z-index: 100;
        width: 600px;
        height: 400px;
    } */
`;

const PaymentMethod = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 99%;
    height: 50px;
    /* border: 1px solid lightgray; */

    > div {
        margin-left: 10px;
    }

    &#creditcard {
        color: ${props => props.selectedMethod === "creditcard"? "rgb(25,144,198)": "gray"};
        border: ${props => props.selectedMethod === "creditcard"? "1px solid rgb(25,144,198)": "1px solid lightgray"};

        &:hover {
            cursor: default;
            color: rgb(25,144,198);
            border-color: rgb(25,144,198);
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            margin-right: 10px;

            > div.card-logo-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: auto;
                max-width: 130px;
                height: 30px;
    
                > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    
    &#toss_payment {
        color: ${props => props.selectedMethod === "toss"? "rgb(25,144,198)": "gray"};
        border: ${props => props.selectedMethod === "toss"? "1px solid rgb(25,144,198)": "1px solid lightgray"};

        &:hover {
            cursor: default;
            color: rgb(25,144,198);
            border-color: rgb(25,144,198);
        }

        > img {
            height: 100%;
            width: auto;
        }
    }
`;

export default function Toss() {
    const paymentWidgetRef = useRef(null)
    const paymentMethodsWidgetRef = useRef(null)

    const [ isLoading, setIsLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(false);

    const tossClientKey = `${process.env.REACT_APP_TOSS_CLIENTKEY}`;
    const tossSecretKey = `${process.env.REACT_APP_TOSS_SECRETKEY}`;
    const tossCustomerKey = "wVExYyZkRf4D"
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const isUserLogin = useSelector(state => state.user.isUserLogin);
    const checkoutItems = useSelector(state => state.user.cartItems);
    const postalCode = useSelector(state => state.user.postalCode);
    const address = useSelector(state => state.user.address);
    const apartment = useSelector(state => state.user.apartment);
    const email = useSelector(state => state.user.email);
    const firstName = useSelector(state => state.user.firstName);
    const lastName = useSelector(state => state.user.lastName);
    const phoneNumber = useSelector(state => state.user.phone);
    const userKey = useSelector(state => state.user.userKey);

    // const [ selectedMethod, setSelectedMethod ] = useState("creditcard");
    // const [ userInfo, setUserInfo ] = useState(null);

    const cnRef = useRef();
    const edRef = useRef();
    const cvcRef = useRef();
    const pwRef = useRef();
    const bdRef = useRef();

    const generateRandomString = (num) => {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < num; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const paynowHandler = async(el) => {
        // let tossCustomerKey = "";
        console.log(apartment)
        if(alertMessage) { 
            return;
        }
        if(!email || !postalCode || !address || !apartment || !phoneNumber || !firstName || !lastName) {
            console.log("email:", email, " postalCode:", postalCode, " address:", address, " apartment:", apartment, " phoneNumber:", phoneNumber, " firstName:", firstName, " lastName:", lastName)
            setAlertMessage(true);
            setTimeout(() => { setAlertMessage(false) }, 2000 )
            return;
        } 
        // 주소 토큰에 저장 
        // await axios.post(
        //     `${process.env.REACT_APP_URL}/user/updateAddress`,
        //     {
        //         email: email,
        //         country: "KR",
        //         postalCode: postalCode,
        //         province: null,
        //         city: null,
        //         address: address,
        //         apartment: apartment,
        //         phoneNumber: phoneNumber,
        //     },
        //     {
        //         withCredentials: true,
        //         "Content-Type": "application/json",
        //     },
        // )
        // if(isUserLogin && window.confirm("주소를 저장하시겠습니까?")) {
        //     // 주소 저장 
        //     console.log("heoooooooo")
        //     await axios.post(
        //         `${process.env.REACT_APP_URL}/user/addAddress`,
        //         {
        //             country: "KR",
        //             email: email,
        //             postalCode: postalCode,
        //             address: address,
        //             apartment: apartment,
        //             phoneNumber: phoneNumber,
        //             firstName: firstName,
        //             lastName: lastName,
        //         },
        //         {
        //             withCredentials: true,
        //             sameSite: "none",
        //         }
        //     )
        //     .then((res) => {
        //         console.log(res);
        //         alert("성공적으로 저장했습니다.")
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         alert("저장하지 못했습니다. 다시 시도해주세요")
        //         return;
        //     })
        // }

        const orderName = `${checkoutItems[0].name} 외 ${checkoutItems.length-1}건`;
        const customerName = `${firstName} ${lastName}`;
        const orderId = generateRandomString(64);

        const paymentWidget = paymentWidgetRef.current 
        try {
            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
            // 더 많은 결제 정보 파라미터는 결제위젯 SDK에서 확인하세요.
            // https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
            await paymentWidget?.requestPayment({
                orderId: orderId,
                orderName: orderName,
                customerName: customerName,
                customerEmail: email,
                successUrl: `http://localhost:3000/success`, // 결제 성공시 이동할 URL 여기에서 결제금액을 확인할거니까 걱정ㄴㄴ
                // successUrl: `https://www.intentionsunknown.com/success`, // 결제 성공시 이동할 URL 여기에서 결제금액을 확인할거니까 걱정ㄴㄴ
                failUrl: `http://localhost:3000/fail`, // 결제 실패시 이동할 URL
                // failUrl: `https://www.intentionsunknown.com/fail`, // 결제 실패시 이동할 URL
            }) 
        } catch (error) {
            // 에러 처리하기
            console.error(error) 
        }
    }

    useEffect(() => {
        console.log("client key:", tossClientKey)
        const amount = checkoutItems.reduce((acc, cur) => { return acc += (cur.price * cur.count); }, 0);
        (async () => {
            const customerId = generateRandomString(42)
            // ------  결제위젯 초기화 ------
            // 비회원 결제에는 customerKey 대신 ANONYMOUS를 사용하세요.
            let paymentWidget;
            if(isUserLogin) { paymentWidget = await loadPaymentWidget(tossClientKey, userKey) } // 회원 결제
            if(!isUserLogin) { paymentWidget = await loadPaymentWidget(tossClientKey, ANONYMOUS) } // 비회원 결제
            // ------  결제위젯 렌더링 ------
            // 결제수단 UI를 렌더링할 위치를 지정합니다. `#payment-method`와 같은 CSS 선택자와 결제 금액 객체를 추가하세요.
            // DOM이 생성된 이후에 렌더링 메서드를 호출하세요.
            // https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
            const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
                "#payment-widget",
                amount,
            )
            // ------  이용약관 렌더링 ------
            // 이용약관 UI를 렌더링할 위치를 지정합니다. `#agreement`와 같은 CSS 선택자를 추가하세요.
            // https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자
            paymentWidget.renderAgreement("#agreement") 
            paymentWidgetRef.current = paymentWidget 
            paymentMethodsWidgetRef.current = paymentMethodsWidget 
        })()
    }, [])
    const paynowRef = useRef();

    return (
        <TossComp>
            <div id="payment-widget" ref={paymentWidgetRef}></div>
            <div id="payment-method" ref={paymentMethodsWidgetRef}></div>
            <div id="agreement"></div>
            <div id="paynow_button" onClick={paynowHandler}>
                {
                    alertMessage?
                    <div id="alert-message">Please fill in the blanks</div>:
                    <div ref={paynowRef}>pay now</div>
                }
            </div>
        </TossComp>
    )
}

