import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setCheckoutInfos } from "../redux/rootSlice";
// import { setCartItems, setOutOfStockList } from "../redux/rootSlice";

const CartItemComp = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-bottom: ${(props) => (props.isLastItem ? "none" : "1px solid lightgray")}; */
    border-bottom: 1px solid lightgray;
`;

const ImageSide = styled.div`
    height: 100%;
    width: 80px;
    min-width: 80px;
    margin-right: 10px;

    > img {
        /* width: 100%; */
        height: 100%;

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
`;

const InfoSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
    height: 80%;
    color: rgb(66,66,66);

    > div#itemname {
        display: flex;
        align-items: center;
        height: 100%;
        /* border: 1px solid red; */
        font-weight: lighter;
        /* color: rgb(68,68,68); */
    }

    > div#setprice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        font-weight: lighter;

        > div#price {
            /* color: rgb(68,68,68); */
        }

        > div.quantity {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 80px;
            height: 60%;
            border-top: 1px solid lightgray;
            border-bottom: 1px solid lightgray;

            > div.counter {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 20px; 
                border: 1px solid lightgray;
                color: rgb(66,66,66);
                opacity: 1;

                &:hover {
                    cursor: default;
                    /* color: lightgray; */
                    color: rgb(25,144,198);
                }

                &:active {
                    color: rgb(18,97,134);
                }
            }

            > div#num {
                cursor: default;
            }
        }
    }
`;

export default function CartItem ({ 
    item, 
    idx, 
    length, 
    contentHandler, 
    cartItems,
    setCartItems,
}) {
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    // const cartItems = useSelector(state => state.user.cartItems);
    const outOfStockList = useSelector(state => state.user.outOfStockList);
    const [ isLastItem, setIsLastItem ] = useState(false);

    const [ count, setCount ] = useState(1);

    const countHandler = async(el) => {
        console.log(el)
        if(el === "m") {
            if(item.count > 1) {
                console.log("item.count: ", item.count)
                // setCount(count - 1);
                // console.log(item)
                let cloneItem = {...item};
                cloneItem.count = cloneItem.count - 1;
                const head = cartItems.slice(0, idx);
                const tail = cartItems.slice(idx+1);
                const requestItems = [...head, cloneItem, ...tail];
                setCartItems(requestItems);

                await axios.post(
                    `${process.env.REACT_APP_URL}/items/cartReq`,
                    {
                        rt: "cart-update",
                        cartItems: requestItems,
                    },
                    {
                        withCredentials: true,
                        sameSite: "none",
                    }
                )
                .then((res) => {
                    console.log(res);
                    return;
                })
                .catch((err) => {
                    console.log(err);
                })
            }
            if(item.count === 1) {
                console.log("item count === 1")
                // 카트에서 제거 토큰 업데이트
                const updatedCartItems = cartItems.filter((item, index) => {
                    return index !== idx;
                });
                console.log("updatecartitems: ", updatedCartItems)
                setCartItems(updatedCartItems);
                await axios.post(
                    `${process.env.REACT_APP_URL}/items/cartReq`,
                    {
                        rt: "cart-delete",
                        cartItems: updatedCartItems,
                    },
                    {
                        withCredentials: true,
                        sameSite: "none",
                    }
                )
                .then((res) => {
                    console.log(res);
                    console.log("updatedCartItems ???????????", updatedCartItems)
                    if(updatedCartItems.length === 0) { contentHandler("block"); }
                    if(updatedCartItems.length > 0) { contentHandler("cart-refresh") }
                    return;
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
        else if(el === "p") {
            // 제고 물량보다 더 못올리게 하기
            console.log(cartItems[idx].count)
            const stockQuantity = await axios.post(
                `${process.env.REACT_APP_URL}/items/cartReq`,
                {
                    rt: "stock-quantity",
                    itemid: item.id,
                    size: item.selectedSize
                }
            )
            .then((res) => {
                console.log(res);
                return res.data.stockquantity;
            })
            .catch((err) => {
                console.log(err)
            })

            if(item.count < stockQuantity) {
                // setCount(count + 1);
                let cloneItem = {...item};
                cloneItem.count = cloneItem.count + 1;
                const head = cartItems.slice(0, idx);
                const tail = cartItems.slice(idx+1);
                const requestItems = [...head, cloneItem, ...tail];
                // dispatch(setCheckoutInfos({type: "ci", data: requestItems})); 
                setCartItems(requestItems);


                await axios.post(
                    `${process.env.REACT_APP_URL}/items/cartReq`,
                    {
                        rt: "cart-update",
                        cartItems: requestItems,
                    },
                    {
                        withCredentials: true,
                        sameSite: "none",
                    }
                )
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                })
            }
        }
    }

    useEffect(() => {
        outOfStockList.forEach((outOfStockItem) => {
            if(outOfStockItem.id === item.id) {
                const itemQuentity = outOfStockItem.count;
                
            }
        })       
    }, [outOfStockList])

    useEffect(() => {
        console.log(item)
        if(idx === length - 1) {
            setIsLastItem(true);
        }
    }, [])

    return (
        <CartItemComp isLastItem={isLastItem}>
            <ImageSide onClick={()=>{ navigate(`/itempage/${item.id}`) }}>
                <img src={item.cartthumbnail} />
            </ImageSide>
            <InfoSide>
                <div id="itemname">
                    {item.name}  [{item.selectedSize}]
                </div>
                <div id="setprice">
                    <div id="price">
                        ₩{item.price}
                    </div>
                    <div className="quantity">
                        <div className="counter" onClick={() => {countHandler("m")}}>-</div>
                        <div id="num">{item.count}</div>
                        <div className="counter" onClick={() => {countHandler("p")}}>+</div>
                    </div>
                </div>
            </InfoSide>
        </CartItemComp>
    )
}