import './App.css';
import styled from 'styled-components';
import { Routes, Route } from "react-router-dom";
import Main from "./pages/main";
import ItemPage from "./pages/itempage";
import Checkout from "./pages/checkout";
import Success from './pages/success';
import TossFail from './pages/tossFail';
import Terms from './pages/terms';
import Contact from './pages/contact';


const AppContainer = styled.div`

`;

function App() {

  return (
      <AppContainer className='app'>
          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/itempage/:id' element={<ItemPage />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path="/success" element={<Success />} />
            <Route path="/fail" element={<TossFail />} />
            <Route path="/terms/:idx" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
      </AppContainer>
  );
}

export default App;
