import styled from 'styled-components';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    setIsUserLogin,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setUserAddressList,
    setCartItems,
    setUserKey,
} from '../redux/rootSlice';
import { useNavigate } from 'react-router-dom';
import LoadingComp from '../components/loading';

import InformationCheckout from '../components/informationCheckout';
import ItemsList from '../components/itemsList';

const CheckoutPageWrapper = styled.div`
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 768px) {
        height: auto;
    }
`;

const CheckoutPage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const CheckoutInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    width: 55%;
    /* max-width: 400px; */
    height: 100%;
    /* border: 1px solid green; */
    margin-left: 20px;
    overflow: scroll;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 5px;
        margin-right: 5px;
        overflow: visible;
    }

    > div#checkoutInfoWrapper {
        width: 50%; 
        /* min-width: 400px; */
        /* min-width: 300px; */
        height: auto; 
        /* height: 100%; */
        /* border: 1px solid black; */

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        > div#logo {
            display: flex;
            align-items: center;
            justify-content: center;
            /* width: 400px; */
            min-width: 300px;
            /* height: 80px; */
            height: 5%;
            min-height: 60px;
            margin-top: 20px;
            margin-bottom: 20px;
            /* box-shadow: 0px 0px 3px 0px rgb(2,2,2); */

            > div#img-wrapper {
                width: 70%;
                /* min-width: 296px; */
                width: 300px;
                /* height: 80%; */
                height: 60px;
                /* min-height: 60px; */
                display: flex;
                align-items: center;
                justify-content: center;
                /* border: 1px solid black; */
                box-shadow: 0px 0px 3px 0px rgb(0,0,0);
                /* opacity: 0.7; */
                background-color: white;
                filter: invert(100%);

                :hover {
                    filter: invert(0);
                    transition: 0.3s;
                }

                :active {
                    opacity: 1;
                }

                
                > img {
                    width: 80%;
                    height: 80%;
                }
            }
        }
    }
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 90px;
    
    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export default function Checkout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [ isLoading, setIsLoading ] = useState(true);
    
    const verifyCheckout = async () => {
        // await axios.get(
        //     `${process.env.REACT_APP_URL}/user/getUserAddress`,
        //     {
        //         withCredentials: true,
        //         sameSite: "none",
        //     }
        // )
        await axios.get(
            // `${process.env.REACT_APP_URL}/items/cartReq`,
            `${process.env.REACT_APP_URL}/user/verify`,
            // {
            //     rt: "get-cart",
            // },
            {
                withCredentials: true,
                sameSite: "none",
            }
        )
        .then((res) => {
            console.log(res.data);
            const response = res.data;
            //카트에 담긴 아이템이 없다면 alert후 홈으로 돌아가기
            if(response.items.length <= 0) {
                alert("cart is empty");
                navigate("/");
                return;
            }
            //유저가 로그인한 상태이고 유저의 id가 있다면 로그인 여부 true;
            // if(response.tok.user) {
            //     dispatch(setIsUserLogin(true));
            // }
            //카트 아이템들을 store에 저장
            dispatch(setCartItems([...res.data.items]));
            //유저가 로그인한 상태에서 이미 저장한 주소가 있다면 store에 저장
            // console.log(response.data)
            // if(response.data.user.UserInfo) {
            // dispatch(setUserAddressList(response.data.user.UserInfos));   
            // }
            //유저가 로그인한 상태에서 유저의 정보가 있다면 store에 저장
            // if(response.data.user) {
            // const val = response.data.user;
            // dispatch(setFirstName(val.firstName));
            // dispatch(setLastName(val.lastName));
            // dispatch(setEmail(val.email));
            // dispatch(setPhone(val.phoneNumber));
            // dispatch(setUserKey(val.userKey));
            // }
            setIsLoading(false);
            return;
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
            //토큰이 만료되었다면 홈으로 돌아가기
            if(err.response.data.message === "TE") {
                navigate('/')
            }
        })

    }

    useEffect(() => {
        verifyCheckout();
    }, [])

    return (
        <CheckoutPageWrapper>
            {
                isLoading ? 
                <LoadingComp /> :
                <CheckoutPage>
                    <ItemsList />
                    <CheckoutInfo>
                        <div id="checkoutInfoWrapper">
                            <InputBox>
                                <InformationCheckout/>
                            </InputBox>
                        </div>
                    </CheckoutInfo>
                </CheckoutPage>
            }
        </CheckoutPageWrapper>
    )
}